import "./footer.scss"

import Button from "../button/Button"
import logo from "../../assets/images/logo-light.svg"

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-col">
        <h4>Funds</h4>
        <p>CCLFX</p>
        <p>CELFX</p>
      </div>
      <div className="footer-col">
        <h4>Resources</h4>
        <p>CDLI</p>
        <p>Research</p>
        <p>Webcasts</p>
      </div>
      <div className="footer-col">
        <h4>Company</h4>
        <p>About Cliffwater</p>
        <p>Locations</p>
        <p>Careers</p>
      </div>
      <div className="footer-col">
        <h4>Legal</h4>
        <p>Privacy Policy</p>
        <p>Terms of Service</p>
        <p>Disclosurs</p>
      </div>
      <div className="footer-col full-height">
        <img className="logo" src={logo} alt={logo} />
        <Button label="Contact Us" />
      </div>
    </div>
  )
}

export default Footer
