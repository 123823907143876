import { useLayoutEffect, useRef } from "react"

import { gsap, Power1, Power3, Power4 } from "gsap/dist/gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"

import "./App.scss"

import SiteNav from "./siteNav/SiteNav"
import SiteBanner from "./siteBanner/SiteBanner"
import MidCard from "./midCard/MidCard"
import Card from "./card/Card"
import GraphBanner from "./graph/GraphBanner"
import Ready from "./ready/Ready"
import Footer from "./footer/Footer"

import play_btn from "../assets/images/play_btn.svg"
import chart from "../assets/images/CW-28_1@2x.png"
import video from "../assets/images/Video_Thumb@2x.png"

gsap.registerPlugin(ScrollTrigger)

function App() {
  //
  const cardsRef1 = useRef()
  const labelRef1 = useRef()
  const headerRef1 = useRef()
  const bodyRef1 = useRef()
  const ctaRef1 = useRef()
  const iconRef1 = useRef()
  //
  const dividerRef = useRef()
  //
  const cardsRef2 = useRef()
  const labelRef2 = useRef()
  const headerRef2 = useRef()
  const bodyRef2 = useRef()
  const ctaRef2 = useRef()
  const iconRef2 = useRef()

  const timeline = useRef(
    gsap.timeline({
      defaults: {
        ease: Power1.easeOut,
      },
    })
  )

  const animIn = () => {
    const { current: label1 } = labelRef1
    const { current: header1 } = headerRef1
    const { current: body1 } = bodyRef1
    const { current: cta1 } = ctaRef1
    const { current: icon1 } = iconRef1
    //
    const { current: divider } = dividerRef
    //
    const { current: label2 } = labelRef2
    const { current: header2 } = headerRef2
    const { current: body2 } = bodyRef2
    const { current: cta2 } = ctaRef2
    const { current: icon2 } = iconRef2

    const duration = 1

    const labels = [label1, label2]
    const headers = [header1, header2]
    const bodies = [body1, body2]
    const ctas = [cta1, cta2]
    const icons = [icon1, icon2]

    //
    gsap.fromTo(divider, { scaleY: 0 }, { scale: 1, duration: duration * 2, ease: Power4.easeInOut })
    //
    gsap.fromTo([icons], { scale: 1.2 }, { scale: 1, duration: duration * 2 })
    gsap.fromTo([icons], { alpha: 0 }, { alpha: 1, duration: duration * 2, ease: Power4.easeInOut })
    //
    gsap.fromTo([labels], { y: 50 }, { y: 0, duration })
    gsap.fromTo([labels], { alpha: 0 }, { alpha: 1, duration, ease: Power4.easeInOut })
    //
    gsap.fromTo([headers], { y: 50 }, { y: 0, duration, delay: 0.3 })
    gsap.fromTo([headers], { alpha: 0 }, { alpha: 1, duration, delay: 0.3, ease: Power4.easeInOut })
    //
    gsap.fromTo([bodies], { y: 50 }, { y: 0, duration, delay: 0.6 })
    gsap.fromTo([bodies], { alpha: 0 }, { alpha: 1, duration, ease: Power4.easeInOut, delay: 0.6 })
    //
    gsap.fromTo([ctas], { x: -50 }, { x: 0, duration, delay: 1 })
    gsap.fromTo([ctas], { alpha: 0 }, { alpha: 1, duration, ease: Power4.easeInOut, delay: 1 })
  }

  useLayoutEffect(() => {
    window.scrollTo(0, 0)

    const tl = timeline.current
    const ctx = gsap.context(() => {
      for (let i = 0; i < cardsRef1.current.childNodes.length; i++) {
        const child = cardsRef1.current.childNodes[i]
        tl.from(child, { alpha: 0, duration: 0.5, ease: Power3.easeInOut }, 0.75 + 0.15 * i)
      }
    }, cardsRef1)

    ScrollTrigger.create({
      trigger: cardsRef2.current,
      // start: "top 75%",
      // end: "90% 40%",
      // markers: true,
      toggleActions: "restart anim restart anim",
      onEnter: () => animIn(),
      // onEnterBack: () => animIn(),
      // onLeave: () => animOut(),
      // onLeaveBack: () => animOut(),
    })

    return () => {
      ctx?.revert()
    }
  }, [])

  return (
    <div className="App">
      <SiteNav />
      <SiteBanner />
      <div className="cards" ref={cardsRef1}>
        <Card
          label="Cliffwater Corporate Lending Fund"
          header="CCLFX"
          body="Focuses on consistent floating-rate income through all environments with low volatility, attractive pricing and a broad diversity of loans originated through multiple high-caliber institutional lenders."
        />
        <Card
          label="Cliffwater Enhanced Lending Fund"
          header="CELFX"
          body="Focuses on delivering exposure to a diversified portfolio of enhanced private debt strategies with low correlation to traditional asset classes, attractive yields and risk-adjusted returns."
        />
      </div>
      <MidCard />
      <GraphBanner />
      <div className="cards-2" ref={cardsRef2}>
        <Card
          label="Cliffwater Lending Index"
          header="Master direct lending with our index"
          body="The CDLI is as an asset-weighted index of<br/>directly originated US middle market corporate<br/>loans. It helps investors understand private debt."
          cta="View Website"
          labelRef={labelRef1}
          headerRef={headerRef1}
          bodyRef={bodyRef1}
          ctaRef={ctaRef1}
          disableHover
        >
          <img className="graph-icon" src={chart} alt={chart} ref={iconRef1} />
        </Card>
        <div ref={dividerRef} className="divider" />
        <Card
          label="Featured Video"
          header="Q3 CDLI Results & Perspectives"
          body="Hear Stephen Nesbitt's perspective on<br/>third quarter results of the Cliffwater Direct<br/>Lending Index and his current outlook for U.S.<br/>middle market debt."
          cta="Watch Webcast"
          labelRef={labelRef2}
          headerRef={headerRef2}
          bodyRef={bodyRef2}
          ctaRef={ctaRef2}
          disableHover
        >
          <div className="video-graphic" ref={iconRef2}>
            <img className="thumb-icon" src={video} alt={video} />
            <img className="play-btn-icon" src={play_btn} alt={play_btn} />
          </div>
        </Card>
      </div>
      <Ready />
      <Footer />
    </div>
  )
}

export default App
