import React, { useLayoutEffect, useRef } from "react"
import { gsap, Power1, Power3, Power4 } from "gsap/dist/gsap"

// import LineBanner from "./LineBanner"
// import LineBannerAlt from "./LineBannerAlt"
import LineBannerAltSlow from "./LineBannerAltSlow"
import Button from "../button/Button"
import { splitText } from "../../utils/splitText"

import "./site-banner.scss"

const SiteBanner = () => {
  const timeline = useRef(
    gsap.timeline({
      defaults: {
        ease: Power1.easeOut,
      },
    })
  )

  const headerRef = useRef()
  const taglineRef = useRef()
  const navRef = useRef()

  useLayoutEffect(() => {
    const tl = timeline.current

    const { current: header } = headerRef
    const { current: tagline } = taglineRef

    let duration = 1
    let delay = 0
    for (let i = 0; i < header.childNodes.length; i++) {
      const word = header.childNodes[i]

      tl.from(word, { y: 5, duration, ease: Power4.easeInOut }, delay)
      tl.to(word, { alpha: 1, duration, ease: Power3.easeInOut }, delay)

      delay += 0.1
    }

    //

    duration = 0.5
    delay += 0.2
    tl.from(tagline, { y: 50, duration: 0.5 }, delay)
    tl.to(tagline, { alpha: 1, duration: 0.5, ease: Power3.easeInOut }, delay)
    //
    delay += duration * 0.5
    for (let i = 0; i < navRef.current.childNodes.length; i++) {
      const child = navRef.current.childNodes[i]
      tl.from(child, { x: 50, duration }, delay)
      tl.to(child, { alpha: 1, duration }, delay)
      delay += 0.15
    }
  }, [])

  return (
    <div className="site-banner">
      <h1 ref={headerRef}>{splitText(`Diversify your portfolio with private debt`)}</h1>
      <div className="tagline">
        <h4 ref={taglineRef}>
          We are an alternative investment adviser and asset manager that provides access to private debt.
        </h4>
        <div className="nav" ref={navRef}>
          <Button label="View Products" variant="dark" />
          <Button label="Contact Us" variant="line" />
        </div>
      </div>
      {/* <LineBanner /> */}
      {/* <LineBannerAlt /> */}
      <LineBannerAltSlow />
    </div>
  )
}

export default SiteBanner
