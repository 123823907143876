import { forwardRef } from "react"
import "./button.scss"

const Button = forwardRef(({ label, variant }, ref) => {
  return (
    <div ref={ref} className={`button${variant ? ` ${variant}` : ""}`}>
      {label}
    </div>
  )
})

export default Button
