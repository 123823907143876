import React, { useEffect, useRef } from "react"
import renderHTML from "html-react-parser"

import ArrowButton from "../arrowButton/ArrowButton"

import { gsap, Power3, Power4 } from "gsap/dist/gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"

import { splitText } from "../../utils/splitText"

import "./mid-card.scss"

gsap.registerPlugin(ScrollTrigger)

const Stat = ({ header, label }) => {
  return (
    <div className="stat-item">
      {header && <h4>{header}</h4>}
      {label && <label>{renderHTML(label)}</label>}
    </div>
  )
}

const MidCard = () => {
  const cardRef = useRef()
  const headerRef = useRef()
  const bodyRef = useRef()
  const statsRef = useRef()
  const ctaRef = useRef()

  const animIn = () => {
    animOut()

    const { current: header } = headerRef
    const { current: body } = bodyRef
    const { current: stats } = statsRef
    const { current: cta } = ctaRef

    let duration = 1.3
    let delay = 0
    for (let i = 0; i < header.childNodes.length; i++) {
      const word = header.childNodes[i]

      gsap.to(word, { y: 0, duration, ease: Power4.easeInOut, delay })
      gsap.to(word, { alpha: 1, duration, ease: Power3.easeInOut, delay })

      delay += 0.1
    }
    //
    delay += 0.2
    duration = 1
    gsap.to(body, { y: 0, duration, delay })
    gsap.to(body, { alpha: 1, duration, ease: Power4.easeInOut, delay })
    //

    delay += 0.75
    for (let i = 0; i < stats.childNodes.length; i++) {
      const child = stats.childNodes[i]

      if (i % 2 === 0) gsap.to(child, { y: 0, duration: 0.5, delay })
      gsap.to(child, { alpha: 1, duration: 0.5, ease: Power4.easeInOut, delay })

      delay += 0.1
    }

    //
    gsap.to(cta, { x: 0, duration, delay })
    gsap.to(cta, { alpha: 1, duration, ease: Power4.easeInOut, delay })
  }

  const animOut = () => {
    const { current: header } = headerRef
    const { current: body } = bodyRef
    const { current: stats } = statsRef
    const { current: cta } = ctaRef

    for (let i = 0; i < header.childNodes.length; i++) {
      const word = header.childNodes[i]
      gsap.killTweensOf(word)
      gsap.set(word, { y: 10, alpha: 0 })
    }
    //
    gsap.killTweensOf(body)
    gsap.set(body, { y: 50, alpha: 0 })

    //
    for (let i = 0; i < stats.childNodes.length; i++) {
      const child = stats.childNodes[i]
      gsap.killTweensOf(child)

      gsap.set(child, { y: i % 2 === 0 ? 50 : 0, alpha: 0 })
    }

    //
    gsap.killTweensOf(cta)
    gsap.set(cta, { x: -50, alpha: 0 })
  }

  useEffect(() => {
    ScrollTrigger.create({
      trigger: cardRef.current,
      start: "top 95%",
      // end: "90% 50%",
      // markers: true,
      // onEnter: () => tl.play(),
      // onEnterBack: () => tl.play(),
      // onLeave: () => tl.reverse(),
      // onLeaveBack: () => tl.reverse(),
      onEnter: () => animIn(),
      onEnterBack: () => animIn(),
      onLeave: () => animOut(),
      onLeaveBack: () => animOut(),
    })

    animOut()
  }, [])

  return (
    <div className="mid-card" ref={cardRef}>
      <h1 ref={headerRef}>{splitText("A Pioneer in Private Credit")}</h1>
      <p className="body" ref={bodyRef}>
        Cliffwater is a leader in alternatives and private credit. We provide access to exclusive loan opportunities
        through our team of investment partners, who we believe are the most admired and successful lenders in private
        credit. Our goal is to bring diversification, pricing, and institutional-caliber portfolio management to
        individual investor portfolios.
      </p>
      <div className="stats" ref={statsRef}>
        <Stat header={31} label="Private Debt<br/>Professionals" />
        <div className="divider" />
        <Stat header="$25B" label="Total Private Debt<br/>Assets" />
        <div className="divider" />
        <Stat header={2006} label="Your Cliffwater Began<br/>Investing in Private Debt" />
      </div>
      <ArrowButton ref={ctaRef} label="Learn More About Cliffwater" />
    </div>
  )
}

export default MidCard
