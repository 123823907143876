import renderHTML from "html-react-parser"

export const splitText = str => {
  const array = str.split(" ")
  return array.map((word, i) => {
    return (
      <div key={i} className="split-child">
        {renderHTML(`${word}${i < array.length - 1 ? "&nbsp;" : ""}`)}
      </div>
    )
  })
}
