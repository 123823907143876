import { forwardRef } from "react"
import renderHTML from "html-react-parser"

import ArrowButton from "../arrowButton/ArrowButton"

import "./card.scss"

const Card = forwardRef(
  ({ body, bodyRef, children, cta, ctaRef, disableHover, header, headerRef, labelRef, label }, ref) => {
    return (
      <div ref={ref} className={"card" + (children ? " breakable" : "") + (disableHover ? " disableHover" : "")}>
        {children}
        <div className="main-card">
          <div className="content">
            {label && <label ref={labelRef}>{label}</label>}
            {header && <h1 ref={headerRef}>{header}</h1>}
            {body && <p ref={bodyRef}>{renderHTML(body)}</p>}
          </div>

          <ArrowButton ref={ctaRef} label={cta || "Learn More"} />
        </div>
      </div>
    )
  }
)

export default Card
