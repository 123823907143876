import { Fragment, useEffect, useRef, useState } from "react"
import Card from "../card/Card"
import Graph from "./Graph"

import { gsap, Sine, Power0, Power1, Power2, Power3, Power4, Back } from "gsap/dist/gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"

import "./graph-banner.scss"
import arrow_round from "../../assets/images/round_arrow.svg"

gsap.registerPlugin(ScrollTrigger)

const width = 662 + 140

const GraphBanner = () => {
  const cardRef = useRef()
  const headerRef = useRef()
  const bodyRef = useRef()
  const ctaRef = useRef()

  const graphBannerRef = useRef()
  const anim = useRef()
  const clearAnim = useRef()
  const carouselContentRef = useRef()
  const canMove = useRef(true)

  const [index, setIndex] = useState(0)

  const animIn = () => {
    anim.current && anim.current()
    const { current: header } = headerRef
    const { current: body } = bodyRef
    const { current: cta } = ctaRef

    const duration = 1

    gsap.fromTo(header, { y: 50 }, { y: 0, duration })
    gsap.fromTo(header, { alpha: 0 }, { alpha: 1, duration, ease: Power4.easeInOut })
    //
    gsap.fromTo(body, { y: 50 }, { y: 0, duration, delay: 0.3 })
    gsap.fromTo(body, { alpha: 0 }, { alpha: 1, duration, ease: Power4.easeInOut, delay: 0.3 })
    //
    gsap.fromTo(cta, { x: -50 }, { x: 0, duration, delay: 0.7 })
    gsap.fromTo(cta, { alpha: 0 }, { alpha: 1, duration, ease: Power4.easeInOut, delay: 0.7 })
  }
  const animOut = (duration = 1) => {
    clearAnim.current && clearAnim.current()

    const { current: header } = headerRef
    const { current: body } = bodyRef
    const { current: cta } = ctaRef

    gsap.killTweensOf(header)
    gsap.killTweensOf(body)
    gsap.killTweensOf(cta)

    gsap.to(header, { alpha: 0, duration })
    gsap.to(body, { alpha: 0, duration })
    gsap.to(cta, { alpha: 0, duration })
  }

  const items = [
    <Graph animFunc={anim} clearAnimFunc={clearAnim} />,
    <Graph animFunc={anim} clearAnimFunc={clearAnim} />,
    <Graph animFunc={anim} clearAnimFunc={clearAnim} />,
    <Graph animFunc={anim} clearAnimFunc={clearAnim} />,
  ]

  const gotoSlide = (dir, duration = 0.5) => {
    const { current } = carouselContentRef

    if (current) {
      if (dir === 0) {
        const length = current.childNodes.length
        for (let i = 0; i < length; i++) {
          const child = current.childNodes[i]

          gsap.set(child, { x: i * width })
        }
      } else {
        if (current && canMove.current) {
          canMove.current = false
          const length = current.childNodes.length
          const fullWidth = width * length
          for (let i = 0; i < length; i++) {
            const child = current.childNodes[i]

            const startX = (i + index) * width
            let target = startX - dir * width

            if (target < -width) {
              while (target < -width) target += fullWidth
            } else if (target > width) {
              while (target > width) target -= fullWidth
            }

            const fromX = target - dir * width

            // if (zIndex > 0) {
            gsap.fromTo(
              child,
              { x: fromX },
              {
                duration,
                x: target,
                onComplete:
                  target === 0
                    ? () => {
                        canMove.current = true
                        setIndex(index + dir)
                      }
                    : null,
              }
            )
          }
        }
      }
    }
  }

  useEffect(() => {
    ScrollTrigger.create({
      trigger: graphBannerRef.current,
      start: "top 80%",
      // end: "90% 40%",
      // markers: true,
      toggleActions: "restart anim restart anim",
      onEnter: () => animIn(),
      onEnterBack: () => animIn(),
      onLeave: () => animOut(),
      onLeaveBack: () => animOut(),
    })

    animOut(0)

    setTimeout(() => {
      gotoSlide(0, 0)
    }, 250)
  }, [])

  const prevSlide = () => gotoSlide(-1)
  const nextSlide = () => gotoSlide(1)

  return (
    <div className="graph-banner" ref={graphBannerRef}>
      <Card
        ref={cardRef}
        header="Why private credit?"
        body="Private debt is a rapidly growing asset class among institutional investors, a trend that is expected to continue. Long-term performance for one of the largest segments of private debt, U.S. middle market corporate lending, is shown to have significantly outperformed traditional fixed income."
        {...{ headerRef, bodyRef, ctaRef }}
      />
      <div className="carousel">
        <div className="content" ref={carouselContentRef}>
          {items.map((item, i) => {
            return <Fragment key={i}>{item}</Fragment>
          })}
        </div>
        <div className="nav">
          <img className="left-arrow" src={arrow_round} alt={arrow_round} onClick={prevSlide} />
          <img className="right-arrow" src={arrow_round} alt={arrow_round} onClick={nextSlide} />
        </div>
      </div>
    </div>
  )
}

export default GraphBanner
