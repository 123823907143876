import React, { useEffect, useRef } from "react"
import { gsap, Power4 } from "gsap/dist/gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"

import Button from "../button/Button"

import "./ready.scss"

gsap.registerPlugin(ScrollTrigger)

const Ready = () => {
  const readyRef = useRef()

  const animIn = () => {
    const { current: ready } = readyRef

    const duration = 0.5
    for (let i = 0; i < ready.childNodes.length; i++) {
      const child = ready.childNodes[i]
      const delay = 0.25 * i

      if (!i) gsap.fromTo(child, { y: 25 }, { y: 0, duration, delay })
      gsap.fromTo(child, { alpha: 0 }, { alpha: 1, duration, ease: Power4.easeInOut, delay })
    }
  }

  const animOut = (duration = 0.5) => {
    const { current: ready } = readyRef

    const length = ready.childNodes.length
    for (let i = 0; i < length; i++) {
      const child = ready.childNodes[i]

      gsap.to(child, { alpha: 0, duration, delay: (length - i - 1) * 0.25 })
    }
  }

  useEffect(() => {
    ScrollTrigger.create({
      trigger: readyRef.current,
      start: "top 85%",
      // end: "0% 100%",
      // markers: true,
      toggleActions: "restart anim restart anim",
      onEnter: () => animIn(),
      onEnterBack: () => animIn(),
      onLeave: () => animOut(),
      onLeaveBack: () => animOut(),
    })

    animOut(0)
  }, [])

  return (
    <div ref={readyRef} className="ready">
      <h4>Ready to get started? Our team is ready to help.</h4>
      <Button label="Contact us" />
    </div>
  )
}

export default Ready
