import { forwardRef } from "react"

import "./arrow-button.scss"
import arrow from "../../assets/images/arrow.svg"

const ArrowButton = forwardRef(({ label }, ref) => {
  return (
    <div ref={ref} className="arrow-button">
      {label} <img src={arrow} />
    </div>
  )
})

export default ArrowButton
