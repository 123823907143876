import { useEffect, useRef, useState } from "react"

import "./graph-banner.scss"

import { gsap, Sine, Power0, Power1, Power2, Power3, Power4 } from "gsap/dist/gsap"
import CustomEase from "gsap/CustomEase"

gsap.registerPlugin(CustomEase)

const getDistance = (x1, y1, x2, y2) => {
  return Math.sqrt(Math.pow(y2 - y1, 2) + Math.pow(x2 - x1, 2))
}

const line1 = [
  [0.7549184266204834, 220.99939150238035],
  [1.7349991666710376, 220.8493377534151],
  [2.7280266559276583, 220.69878277873994],
  [3.7232593369336433, 220.54921940477192],
  [4.698382674589157, 220.4040236463547],
  [5.686796977844507, 220.25835762210193],
  [6.680668244870156, 220.1136621143073],
  [7.678528118571192, 219.9705505271107],
  [8.656446412386508, 219.83296413062516],
  [9.65489951605296, 219.69615914142133],
  [10.65677827444941, 219.56462931153177],
  [11.648061298828125, 219.4410888671875],
  [12.633824770414531, 219.32435516682267],
  [13.629672610542775, 219.21108798956874],
  [14.616188788204193, 219.1006655597687],
  [15.615123072869778, 218.98705652546883],
  [16.61241873092502, 218.8687048352063],
  [17.593855080610812, 218.74567934438585],
  [18.583915754071175, 218.6145130367577],
  [19.59031600399017, 218.47570903593305],
  [20.571609472084045, 218.33749040025475],
  [21.5648096665144, 218.19393573928622],
  [22.549060336470603, 218.04731380138546],
  [23.5365968329668, 217.8955058340952],
  [24.532204044604303, 217.73802971810846],
  [25.522297061729432, 217.57795556741954],
  [26.493691270446774, 217.41866374826432],
  [27.491983349013328, 217.2536726006046],
  [28.473146203304456, 217.09055530535247],
  [29.457593965506554, 216.92452400672437],
  [30.444213418078423, 216.7551276897192],
  [31.435742021870613, 216.5814936338663],
  [32.40942865637839, 216.40767714889347],
  [33.401129864904284, 216.22780280731618],
  [34.38173688220978, 216.04803320407868],
  [35.357963456287976, 215.86814697630703],
  [36.34074305527211, 215.68689322531225],
  [37.333580684471144, 215.5039901601076],
  [38.32318300020695, 215.32103432540598],
  [39.304875, 215.13840625],
  [40.27289895267486, 214.956854914546],
  [41.266428405761715, 214.76869742584228],
  [42.25499126491547, 214.5795418893099],
  [43.226274470353125, 214.39197386972603],
  [44.20641546556949, 214.201326696679],
  [45.19258411591053, 214.0085293496698],
  [46.181051599812506, 213.81477754984795],
  [47.148675852775575, 213.62693632376195],
  [48.128651777648926, 213.4387915868759],
  [49.12489085786343, 213.24828958328067],
  [50.107415129089354, 213.05896753025053],
  [51.07792021715641, 212.86799669866264],
  [52.05635671627522, 212.66931384418905],
  [53.039725147819524, 212.46230899488927],
  [54.00838765277862, 212.2512521613836],
  [54.9832526902914, 212.03238055855036],
  [55.97035321802199, 211.80439785625785],
  [56.949133085632326, 211.57153636550902],
  [57.908894042968754, 211.33600512695313],
  [58.88400912189484, 211.08954460096356],
  [59.85411995277107, 210.83846238244323],
  [60.806334349632266, 210.58852027368545],
  [61.78825257189274, 210.32976214689018],
  [62.75218291491568, 210.07681655492632],
  [63.72151908016205, 209.82480740976334],
  [64.68931588408947, 209.57447020605204],
  [65.65728851528168, 209.32123908257486],
  [66.62177460701466, 209.0660666682422],
  [67.59466817626952, 208.80649467468263],
  [68.55195562896729, 208.5502590847015],
  [69.51156875, 208.2942578125],
  [70.49128492572308, 208.035373216182],
  [71.44776142561436, 207.78601610884073],
  [72.42720783843994, 207.53446365928647],
  [73.39920660575926, 207.288919207409],
  [74.35657853221893, 207.053848402977],
  [75.32796010901927, 206.8224514878988],
  [76.30171097164154, 206.59623005199433],
  [77.28186951520145, 206.37124964402616],
  [78.25301466944217, 206.14674264442922],
  [79.2350090887785, 205.9137571395636],
  [80.20643585717679, 205.67444218623638],
  [81.16177367037237, 205.42942490796744],
  [82.14122736980916, 205.1684950319752],
  [83.10375073139666, 204.90584942486137],
  [84.06180205156804, 204.6397005950734],
  [85.02045387136937, 204.36736788212505],
  [85.97895898284912, 204.0879580731392],
  [86.9490699993372, 203.79749611764402],
  [87.90128611602783, 203.50550145101548],
  [88.84824822235106, 203.20977654504776],
  [89.80932461340427, 202.90593303453176],
  [90.75463802931309, 202.6049242253229],
  [91.70474854159579, 202.29493924850968],
  [92.66089216447845, 201.95620159047095],
  [93.5976974698007, 201.59988825398682],
  [94.52788155443072, 201.23000109821555],
  [95.45639431266784, 200.85805392837523],
  [96.38054946673512, 200.50165149873496],
  [97.33185591387749, 200.16504151391982],
  [98.26963528259397, 199.8731686396003],
  [99.24447726578117, 199.61500105887652],
  [100.22528088629245, 199.39955692201855],
  [101.20527017416062, 199.2222362620011],
  [102.19569912187755, 199.08204767417163],
  [103.19956447601318, 198.9814527854919],
  [104.18581864051522, 198.91910541575405],
  [105.18779143428802, 198.88113863134384],
  [106.19618861377239, 198.85180669516325],
  [107.19387615299226, 198.81551458978652],
  [108.17889905022085, 198.76203057613225],
  [109.17325734126568, 198.68523997449876],
  [110.17970470714569, 198.5854883232117],
  [111.17472692143917, 198.46870517873765],
  [112.16544289302827, 198.33876064682008],
  [113.15404256223142, 198.2041651725173],
  [114.13995743776857, 198.07735411459208],
  [115.12855710697174, 197.97023544692993],
  [116.13697873081267, 197.89057741695643],
  [117.13354948227108, 197.84432255619765],
  [118.14185073067247, 197.82900617414714],
  [119.13425677490234, 197.85137091064456],
  [120.13536614024639, 197.92244224512578],
  [121.13060986328125, 198.03889990234376],
  [122.10570995616914, 198.18667996311189],
  [123.09174812519551, 198.3501985248327],
  [124.09391521453858, 198.5056302871704],
  [125.08009844267369, 198.62663074100018],
  [126.06886808013917, 198.70388903045654],
  [127.06775345242025, 198.73363006436827],
  [128.06821113057433, 198.69480830618738],
  [129.05955322265623, 198.5529365234375],
  [130.0286099014282, 198.31791026306155],
  [130.9931273866445, 198.00899797174336],
  [131.93233755791186, 197.67260756754877],
  [132.87942111574114, 197.3447659095824],
  [133.8330342501402, 197.06998557019233],
  [134.81754352055492, 196.8747232121527],
  [135.81065794086456, 196.7807474346161],
  [136.80313619327546, 196.7721470360756],
  [137.80103143310546, 196.77542941284176],
  [138.80738352666796, 196.7892231074199],
  [139.81593969595434, 196.8239930601716],
  [140.8055056295395, 196.8897253127098],
  [141.79755568492413, 196.99562349146603],
  [142.79623596775534, 197.14537041074038],
  [143.77521832565964, 197.33097723322362],
  [144.74125589932504, 197.54563603857903],
  [145.71389798247813, 197.80586664426326],
  [146.62881008185448, 198.20707293982798],
  [147.47705902194977, 198.73203845691683],
  [148.275399714455, 199.3439395198077],
  [149.02453661628067, 199.99159819389882],
  [149.77349999999998, 200.667],
  [150.52246338371933, 201.31964980719982],
  [151.32139013671875, 201.93608349609372],
  [152.1750984426737, 202.4550417815447],
  [153.10670189131795, 202.82418103499708],
  [154.0793981369734, 202.98300425755977],
  [155.08477072037755, 202.97194498509168],
  [156.0825690088272, 202.90242777633668],
  [157.0732845120877, 202.77418000847103],
  [158.05603352211415, 202.58933041363952],
  [159.02733755791186, 202.3600260977745],
  [159.99073348844053, 202.10482893037798],
  [160.962558760643, 201.8404813117981],
  [161.93119913613796, 201.58750373888017],
  [162.90565794086456, 201.35444613265992],
  [163.878042546466, 201.14395123051852],
  [164.8592179633379, 200.93581153315307],
  [165.83414843682945, 200.72914750219135],
  [166.81387890625, 200.51837304687498],
  [167.78937134329976, 200.30123784572632],
  [168.76306030404567, 200.07322805839777],
  [169.73761492733658, 199.8312443298176],
  [170.70468154621125, 199.5771628947258],
  [171.65606981289386, 199.3148527161479],
  [172.6247009413391, 199.03684696106612],
  [173.57324967338144, 198.75030161742865],
  [174.5370986276865, 198.44194086635113],
  [175.4860582743883, 198.1215015462637],
  [176.4233822221756, 197.79108088588714],
  [177.3614565499276, 197.45177488197385],
  [178.30042318041623, 197.110396026358],
  [179.2525142364055, 196.76906734381618],
  [180.18271029376984, 196.44463944149018],
  [181.13295932102204, 196.12456578350066],
  [182.08093018710613, 195.81470840096475],
  [183.03231845378875, 195.5075635623932],
  [183.99938507266344, 195.19934561118484],
  [184.94162530422213, 194.90285476875303],
  [185.9, 194.6045],
  [186.8583746957779, 194.30812277030944],
  [187.81760616838932, 194.01192288279532],
  [188.76768154621124, 193.7176874141693],
  [189.71906981289385, 193.4213641088009],
  [190.66710774707795, 193.12434799289704],
  [191.6365286928862, 192.82370425365866],
  [192.58299698923528, 192.53436644415555],
  [193.54953931844233, 192.2418690251112],
  [194.50281864051522, 191.95398997794092],
  [195.45687488053738, 191.66333057145772],
  [196.41355710697172, 191.36622708606723],
  [197.3689343137741, 191.061835272789],
  [198.32260649871827, 190.7496355819702],
  [199.2591261111647, 190.43526647861304],
  [200.2041538399607, 190.1099756982699],
  [201.15063522951306, 189.77411501859874],
  [202.09685633493962, 189.42812719700487],
  [203.02129338683187, 189.08140991357715],
  [203.9635, 188.722125],
  [204.88955448925495, 188.3674585273862],
  [205.8301436650604, 188.0102202265486],
  [206.77636477048696, 187.65738764787466],
  [207.70311786472797, 187.3199588468671],
  [208.6470496749878, 186.98509681701663],
  [209.60439350128175, 186.65776878356934],
  [210.54040833993258, 186.35089230284092],
  [211.4968757427484, 186.04855747088789],
  [212.45415848171712, 185.75235240340234],
  [213.4082486490011, 185.45589060902594],
  [214.3609941821545, 185.15031986489893],
  [215.30899334041774, 184.83050492659208],
  [216.2526388885826, 184.4935035651028],
  [217.18773418399505, 184.14096044794096],
  [218.1081930047721, 183.77683262168614],
  [219.0492179633379, 183.38675733023882],
  [219.95559540008006, 182.99317058040944],
  [220.8738794631958, 182.5773191719055],
  [221.77287408447268, 182.1568617248535],
  [222.67977083335816, 181.72527441295233],
  [223.57491650390625, 181.29897485351563],
  [224.48159166006744, 180.87318552356214],
  [225.39851548327505, 180.4529834080115],
  [226.312597236678, 180.04645803862064],
  [227.21988562364876, 179.64988708510995],
  [228.13605515761674, 179.2430703112185],
  [229.05570389498774, 178.82979381993414],
  [229.97047644329072, 178.41777754783632],
  [230.86794180393218, 178.01817216682434],
  [231.79141292893885, 177.61817202830315],
  [232.72311622254549, 177.23150985869765],
  [233.65511526872217, 176.86447700098157],
  [234.58339304213226, 176.51854182466866],
  [235.53175428617, 176.18353047823905],
  [236.4677347993851, 175.85097018003464],
  [237.40361346065998, 175.4979523298144],
  [238.34147502422334, 175.13372217988967],
  [239.2767533816546, 174.77704099787027],
  [240.2170215988159, 174.44772050857546],
  [241.1780329275131, 174.16502668619157],
  [242.14423596775532, 173.95202592200042],
  [243.14175535738468, 173.81196603244547],
  [244.12970362506806, 173.74808330062777],
  [245.14291190278527, 173.7423453745842],
  [246.1442179633379, 173.75225980615616],
  [247.136236612916, 173.77153454637528],
  [248.13127213847636, 173.79894393301012],
  [249.13787600348888, 173.83152734106778],
  [250.14512109375, 173.864046875],
  [251.141959610939, 173.89127666091917],
  [252.13671053600314, 173.91027923202515],
  [253.14146102142334, 173.9198505554199],
  [254.1344111251831, 173.90856787109374],
  [255.13896327114108, 173.8014892578125],
  [256.09932364809515, 173.58698930358884],
  [257.04922184334697, 173.26409066581726],
  [257.9629418039322, 172.86084204101562],
  [258.85433412837983, 172.40488671875],
  [259.734660564065, 171.9261282196045],
  [260.6079930755049, 171.4535294971466],
  [261.50572528064254, 170.99371626281737],
  [262.417959321022, 170.56788110351562],
  [263.3261440429687, 170.1729143066406],
  [264.2440314331054, 169.75304678344727],
  [265.13076403224466, 169.32598824316264],
  [266.0318794631958, 168.87454519271853],
  [266.93087408447263, 168.41476705932618],
  [267.8217623519898, 167.96105186843872],
  [268.71630379416047, 167.51891707872602],
  [269.62193431377415, 167.0938900589943],
  [270.5374566179514, 166.691914062202],
  [271.4498922529221, 166.31966871881485],
  [272.39793018710617, 165.96314612317087],
  [273.3493184537888, 165.64185693550112],
  [274.2993938316107, 165.35737791848186],
  [275.25862530422216, 165.1012092075348],
  [276.2328760034889, 164.85961518970132],
  [277.2077278615236, 164.61736445498468],
  [278.16864600372315, 164.3593531951904],
  [279.1212688342333, 164.0720912940502],
  [280.07585595703125, 163.7473076171875],
  [281.00470094133914, 163.39591923642158],
  [281.9342897062302, 163.01604930114746],
  [282.8469015573263, 162.6163067455292],
  [283.750399714455, 162.19457487750051],
  [284.65972647391266, 161.74773147273064],
  [285.55032803843915, 161.29591300082205],
  [286.43422141182424, 160.84367397499085],
  [287.33703125, 160.388],
  [288.2261139589548, 159.95282396125793],
  [289.1269637648612, 159.52967486977576],
  [290.0460913602356, 159.11712842572572],
  [290.9655653085709, 158.71539372873306],
  [291.878308808446, 158.32504441958665],
  [292.7984428930283, 157.94004392004013],
  [293.7231863509864, 157.56055573975294],
  [294.6613956680298, 157.1805112953186],
  [295.5802638935894, 156.8096113232598],
  [296.50686498260495, 156.43344880294802],
  [297.4436006784439, 156.0484805665016],
  [298.3603019232303, 155.66594238919765],
  [299.27229637493195, 155.27923519507794],
  [300.2044564794451, 154.87700368431956],
  [301.1198314260244, 154.4755141763091],
  [302.02813555908205, 154.07225271606447],
  [302.9306043319702, 153.66953484725954],
  [303.85284151828284, 153.25980466765165],
  [304.7604672349691, 152.86228740686178],
  [305.69610509251055, 152.46145019283148],
  [306.60747130711377, 152.08105892551688],
  [307.535600384593, 151.70383533638713],
  [308.46503623513877, 151.33701553589106],
  [309.4026939086914, 150.98193041992187],
  [310.34070389498766, 150.6417364746928],
  [311.2879528831244, 150.31101112794875],
  [312.23235893853007, 149.9887729577422],
  [313.172832657814, 149.6677286491394],
  [314.125656791091, 149.33442463731765],
  [315.0657995347977, 148.99199547958375],
  [316.004554877162, 148.63401633691788],
  [316.92232437737283, 148.268304539226],
  [317.84984261892737, 147.8873966141418],
  [318.77826939947903, 147.4966835620627],
  [319.6936099014282, 147.1012467765808],
  [320.6099752807617, 146.69505117797854],
  [321.51790341840683, 146.28361576092988],
  [322.4305556849241, 145.86361936718225],
  [323.3266460037231, 145.44817513656616],
  [324.24268154621126, 145.02336111211775],
  [325.15442713260654, 144.60257823896407],
  [326.0590496749878, 144.1881312713623],
  [326.958925938651, 143.77384858986736],
  [327.8637572942972, 143.3527052023411],
  [328.77578407211604, 142.92389663651585],
  [329.6740841702223, 142.49833714365957],
  [330.5816715812236, 142.06697852233052],
  [331.49503292751314, 141.63378462791442],
  [332.3927633870841, 141.21098366713525],
  [333.29296121542154, 140.79135773345828],
  [334.2062306205481, 140.3706537515819],
  [335.1118229866773, 139.95838763193785],
  [336.0290121869296, 139.54460106225312],
  [336.94862387712305, 139.13343762116133],
  [337.85733943593505, 138.73097633993626],
  [338.76971626937393, 138.33048163616658],
  [339.69262579345707, 137.9281920776367],
  [340.6079095221311, 137.53072452025117],
  [341.52703125000005, 137.13165625],
  [342.43456090043486, 136.73655669988693],
  [343.35695839498936, 136.3331986130625],
  [344.26698682309694, 135.9330310591534],
  [345.1934605733156, 135.52197707754374],
  [346.10345758056644, 135.1147375793457],
  [347.00499517993626, 134.7088787269816],
  [347.92915848171714, 134.29222524744273],
  [348.83547507442535, 133.88551355666667],
  [349.75386444091794, 133.47787362670897],
  [350.6621685739756, 133.08098117476703],
  [351.5775435205549, 132.68808599846813],
  [352.509703625068, 132.29517027168723],
  [353.4216980767697, 131.91536373897645],
  [354.34776104651206, 131.52249914888947],
  [355.257753935948, 131.12967217791825],
  [356.1853235675096, 130.7272129240632],
  [357.1046798609644, 130.33363563156874],
  [358.0268748805374, 129.9529168270156],
  [358.9504672349691, 129.59340501326324],
  [359.90369119155406, 129.24974883168937],
  [360.85445661795137, 128.93518520146608],
  [361.8083418022394, 128.64552952319383],
  [362.7741393051818, 128.38285939176382],
  [363.73872878646847, 128.1676473007202],
  [364.7343095131889, 127.99421354807913],
  [365.7199689600542, 127.86189341016114],
  [366.7236664805412, 127.7473436460495],
  [367.71877840477225, 127.62706167280675],
  [368.6905729660988, 127.47938495731353],
  [369.67788724916426, 127.28372552959621],
  [370.65405213738234, 127.03980468554796],
  [371.60604254646597, 126.75755026249587],
  [372.5502894639969, 126.44686283397675],
  [373.49359540008004, 126.10456269027293],
  [374.4281684099287, 125.73354454149305],
  [375.3426624420881, 125.34350949203967],
  [376.26583666706085, 124.93109724712372],
  [377.16284833700956, 124.52303862516581],
  [378.0727087783813, 124.11193050384522],
  [378.99388562619686, 123.70607057154177],
  [379.9129503250123, 123.3156669845581],
  [380.8373408124894, 122.92579711816461],
  [381.7475703349561, 122.52084007749706],
  [382.66238352666795, 122.09561930365115],
  [383.55766686131057, 121.66910194420069],
  [384.46997840118405, 121.2357871055603],
  [385.37809071539345, 120.82009530390053],
  [386.29514821086826, 120.42912282199413],
  [387.22951575113836, 120.06871552037447],
  [388.1803204525709, 119.74350966793298],
  [389.1341434213892, 119.45704937794059],
  [390.0842328796387, 119.19587649536132],
  [391.0674703593925, 118.94306440942735],
  [392.0304849935845, 118.72081323157995],
  [393.02342472082375, 118.5271602229476],
  [394.0094887046814, 118.37756781387328],
  [395.0104972862601, 118.27087908893823],
  [395.9945528869629, 118.20571469116211],
  [396.9995168630704, 118.1712480192557],
  [397.9968146078959, 118.1601615147218],
  [398.99865304982666, 118.16847302103042],
  [399.9915963471085, 118.20018512815237],
  [400.9953512116521, 118.25524913936852],
  [401.99121406137937, 118.32785736227035],
  [402.99364487318695, 118.4092551344037],
  [403.99310221917926, 118.48618183487653],
  [404.99148670494554, 118.54776709318162],
  [405.9744346914291, 118.58713684463501],
  [406.9871261111647, 118.60359324783087],
  [407.99123334503173, 118.58988361740113],
  [408.98723346807066, 118.52730619772524],
  [409.97661220824716, 118.40340462857485],
  [410.9585870710612, 118.20860536330939],
  [411.9138374761343, 117.94670591884851],
  [412.8609095221311, 117.6226813859269],
  [413.79723960323633, 117.25319592868537],
  [414.706036728859, 116.86345484685899],
  [415.6300105791092, 116.44965306425095],
  [416.54053793334964, 116.03140020751954],
  [417.4275662029311, 115.58318046809732],
  [418.3038061429784, 115.08840727783739],
  [419.14200891429186, 114.5663492244482],
  [419.97432935508334, 114.00563220210373],
  [420.7868863959312, 113.42811896991729],
  [421.6010931288972, 112.83472274200619],
  [422.4048304100037, 112.25048499298096],
  [423.2310957374573, 111.66555187225342],
  [424.0567003611252, 111.10651683367789],
  [424.89529586786495, 110.56964487305842],
  [425.755744100675, 110.04719731473922],
  [426.61011519841855, 109.53527956533432],
  [427.4759252109975, 109.02230092000963],
  [428.3395644760132, 108.51789575195313],
  [429.1981863509864, 108.02488719701768],
  [430.07273494243617, 107.53201095581055],
  [430.9407334884405, 107.05165775680543],
  [431.8264045999199, 106.56917519426347],
  [432.6959685668945, 106.10112109375001],
  [433.59394487120215, 105.6217448811531],
  [434.4598738834038, 105.1618422171697],
  [435.3581132006049, 104.68873464351891],
  [436.24374938768153, 104.22827203279734],
  [437.1390486698746, 103.76986921018363],
  [438.0219082336426, 103.32543600463868],
  [438.91388451004036, 102.88393989181519],
  [439.826086702086, 102.43909446635098],
  [440.7212235716209, 102.00722680882365],
  [441.6176294150948, 101.5773284201026],
  [442.52613316351176, 101.14253035253286],
  [443.42828088629244, 100.7105079210043],
  [444.33145051772897, 100.27774921137988],
  [445.221269399479, 99.85180351459682],
  [446.13660990142824, 99.41532208099366],
  [447.0369092846066, 98.98932264150082],
  [447.94502159881597, 98.56476092071533],
  [448.84112109375, 98.15227734375],
  [449.76964600372315, 97.73226791229249],
  [450.667429665044, 97.33304523534477],
  [451.59742713260647, 96.92573380794525],
  [452.5020496749878, 96.534614371109],
  [453.4211143738031, 96.14376587235331],
  [454.3600212691873, 95.75291739637927],
  [455.28545527674254, 95.37640620202347],
  [456.21323764801025, 95.0070267436981],
  [457.13592461287976, 94.64577460556627],
  [458.06839426136014, 94.28380787978173],
  [459.007558760643, 93.91907009320259],
  [459.93891779327396, 93.55459321250916],
  [460.8694610214234, 93.1861697681427],
  [461.7914140686486, 92.81696979496937],
  [462.7180973482131, 92.4461955189228],
  [463.6547099838257, 92.0725441127777],
  [464.5812519445866, 91.70356287934109],
  [465.50010474395754, 91.337577891922],
  [466.4383123712391, 90.96289256170019],
  [467.3630339002609, 90.59175815196036],
  [468.2830984426737, 90.22014689493776],
  [469.214701891318, 89.84132993938104],
  [470.145959321022, 89.46021963486672],
  [471.07405512879785, 89.07918031586632],
  [471.99039543579516, 88.7038245777391],
  [472.927236612916, 88.32029794312119],
  [473.84121110598744, 87.94518788127826],
  [474.7700965815931, 87.56145149872675],
  [475.6940907153934, 87.17567119217142],
  [476.6111482108682, 86.78770879664049],
  [477.52761041271685, 86.39461636961101],
  [478.4575114135742, 85.99057196960449],
  [479.3656756226271, 85.59156578068658],
  [480.2836530498266, 85.18593163363337],
  [481.18641857469083, 84.78615014249682],
  [482.1121626291424, 84.37395233761742],
  [483.0189396959543, 83.96625434101225],
  [483.92912399651107, 83.5512134874411],
  [484.83878339385984, 83.12911936683653],
  [485.74405877543984, 82.70124281773046],
  [486.6493955982476, 82.26591540149226],
  [487.54777578218284, 81.82757441894785],
  [488.4398507306725, 81.38719331627563],
  [489.3349991282075, 80.93929882518053],
  [490.2180548993349, 80.4895087325096],
  [491.1098563349396, 80.02670220316648],
  [491.9857792412043, 79.56395406370163],
  [492.8812383309603, 79.08384772939682],
  [493.75758720918, 78.60953805125952],
  [494.6411162225455, 78.12991022478342],
  [495.51963385975364, 77.65430611104965],
  [496.3859878130704, 77.18842552102804],
  [497.2823981369734, 76.7107142083168],
  [498.14914404296877, 76.24544963378906],
  [499.0302179633379, 75.75015006842018],
  [499.8849657498598, 75.2505013379991],
  [500.7568958519697, 74.72856935830713],
  [501.61067986096447, 74.21680601579473],
  [502.4690600282401, 73.71581902249233],
  [503.3411022191792, 73.23489628680572],
  [504.234864982605, 72.78184635658263],
  [505.15288480158154, 72.36313315494283],
  [506.0679838718176, 71.99181784389614],
  [507.02053897857667, 71.65495503616332],
  [507.9882446426153, 71.39256109160186],
  [508.96859540008006, 71.21242325174063],
  [509.9519670724869, 71.10495878648757],
  [510.96061675138776, 71.03816812253147],
  [511.9499172445387, 70.96832621269672],
  [512.9406149273366, 70.84702558853478],
  [513.9259612154216, 70.64360010357946],
  [514.8789448712022, 70.35599516118317],
  [515.807040678978, 69.99113650202752],
  [516.7192747193576, 69.56478961048722],
  [517.5993275042773, 69.09024713407159],
  [518.4570057477952, 68.56738023276328],
  [519.29041279082, 68.00801984968408],
  [520.1032521682978, 67.42646404979826],
  [520.900832657814, 66.83874318165779],
  [521.7026852225214, 66.24967257453874],
  [522.5259930755049, 65.66359408329204],
  [523.3478218284845, 65.1091095310986],
  [524.1921553561837, 64.57738279444054],
  [525.0632963749318, 64.06516637616382],
  [525.9206530717164, 63.56984655883834],
  [526.7890066595824, 63.0749497991018],
  [527.6710558098704, 62.58153503049836],
  [528.5461863509864, 62.103459661383184],
  [529.4207349424363, 61.638553961992265],
  [530.3213942613602, 61.17323986010551],
  [531.2088035795539, 60.72666711748019],
  [532.0992318268568, 60.28804899815843],
  [533.0017868574857, 59.85055130990147],
  [533.9113996154069, 59.41517261672616],
  [534.8205653085708, 58.99302217106818],
  [535.7333088084459, 58.58584339376092],
  [536.6534428930283, 58.191944370603565],
  [537.5781863509864, 57.81015222392902],
  [538.5004750744254, 57.43794221323208],
  [539.4188644409179, 57.06859258728027],
  [540.361864982605, 56.68351935997009],
  [541.2798848015815, 56.298156882939494],
  [542.1949838718175, 55.90145119478107],
  [543.1068979824781, 55.485171213662625],
  [543.9843542752117, 55.020818368224795],
  [544.8350986276864, 54.51011005307436],
  [545.6849660997391, 53.956488183689125],
  [546.5137312206477, 53.401351066397126],
  [547.3570581960678, 52.85751083250046],
  [548.2220471168757, 52.36263625613451],
  [549.135142124489, 51.94618030126244],
  [550.0889448423833, 51.64910190659613],
  [551.0649428440333, 51.49330100051165],
  [552.0565154789687, 51.506639676904676],
  [552.9592304687499, 51.9363015625],
  [553.6814169991035, 52.62535058294758],
  [554.2803938316107, 53.419305647706985],
  [554.8155393184423, 54.27916833004952],
  [555.2961508218069, 55.148755189814416],
  [555.752881567955, 56.03472347602845],
  [556.198, 56.9269],
  [556.643118432045, 57.81907652397156],
  [557.1079172445386, 58.7202542635262],
  [557.58869913142, 59.58881150522306],
  [558.1156061683894, 60.43449435229301],
  [558.7234633828252, 61.238750092726946],
  [559.4462003224176, 61.92447302090898],
  [560.3394845210314, 62.347160323095316],
  [561.3312131425142, 62.34633368102908],
  [562.3073954357951, 62.10508149498477],
  [563.2100403890611, 61.672407502603534],
  [564.0279421835393, 61.10436854974702],
  [564.784881567955, 60.444323187494284],
  [565.4841259155273, 59.74538128967285],
  [566.1722127339393, 59.005573956482856],
  [566.8445240545274, 58.2645995165348],
  [567.5177959551384, 57.53273519511623],
  [568.2077820366621, 56.81591286833882],
  [568.9305669937133, 56.11777546043396],
  [569.6826756226271, 55.458868938363345],
  [570.4625910615772, 54.81722811230346],
  [571.2284583943933, 54.18240749340579],
  [571.9999290436506, 53.536858671706916],
  [572.7633997144551, 52.890956171595306],
  [573.5125366162806, 52.24961356950775],
  [574.277376003459, 51.58685022899881],
  [575.0265214233398, 50.930315133667],
  [575.7761816791295, 50.267096582335235],
  [576.5230709563493, 49.60155309129358],
  [577.2575609004348, 48.94383010365889],
  [578.001117864728, 48.275978916174175],
  [578.7558565671892, 47.5970278046392],
  [579.4937693794519, 46.92884129769727],
  [580.2226782072634, 46.25969318669215],
  [580.9615132950545, 45.57222829621434],
  [581.6951516629904, 44.881218167186525],
  [582.4153414095491, 44.196474972943214],
  [583.1342133079768, 43.509347485011816],
  [583.865791434288, 42.80982970757484],
  [584.5850625415724, 42.125372247917674],
  [585.3129596109391, 41.4390403649807],
  [586.0425338102523, 40.75955646645939],
  [586.779549482271, 40.08272936519459],
  [587.5166003845931, 39.415681276637315],
  [588.2650161281824, 38.749536789649724],
  [589.0295286928862, 38.08680926446989],
  [589.7817572942971, 37.4524315400064],
  [590.5596480880529, 36.81270475534126],
  [591.3342721384764, 36.187501605683565],
  [592.1185531473905, 35.55910663057491],
  [592.897791434288, 34.93013247551918],
  [593.6745414152144, 34.289534035158155],
  [594.4308035795539, 33.64609414027706],
  [595.1744296650439, 32.99076930100247],
  [595.9180438663717, 32.311871559854694],
  [596.6521261111646, 31.61910270964727],
  [597.3576114557534, 30.930263229047508],
  [598.0612496733816, 30.212847906746713],
  [598.742007136941, 29.489434983104463],
  [599.4219337483197, 28.73983697023317],
  [600.0808454030397, 27.991587594734785],
  [600.7357414348871, 27.23309507867023],
  [601.3882521682978, 26.47132587751746],
  [602.0413054598421, 25.71274275507107],
  [602.6895851785988, 24.972911633298544],
  [603.3592961050123, 24.23056456370726],
  [604.051759904908, 23.49235022492362],
  [604.7468680801392, 22.78437084312439],
  [605.4669560130629, 22.086401824022737],
  [606.2055350952148, 21.405188470458988],
  [606.9495242456737, 20.743073251917398],
  [607.7106081653443, 20.090881286882425],
  [608.4873850726634, 19.45371739146262],
  [609.2674458860159, 18.84352747682333],
  [610.0702650575638, 18.244529470539092],
  [610.8809574377686, 17.66386451244503],
  [611.7046764324904, 17.090020475471018],
  [612.5282859233171, 16.522841533572972],
  [613.3473610038609, 15.95706442682594],
  [614.1674271326065, 15.38311179742813],
  [614.9883505392074, 14.797743163232804],
  [615.797734799385, 14.214684991865157],
  [616.6069913793951, 13.630860082220883],
  [617.4171484368294, 13.047570586148053],
  [618.2342665115594, 12.463413557771444],
  [619.0545249255747, 11.884839739012868],
  [619.865118432045, 11.324359719686507],
  [620.703864440918, 10.759001497192383],
  [621.5430342501402, 10.20936517377019],
  [622.3793610038608, 9.677014072674663],
  [623.2390698128938, 9.14414363804698],
  [624.0831454903334, 8.632722190263273],
  [624.945923735261, 8.1085224417305],
  [625.8011752929688, 7.5841379296875],
  [626.6524819831401, 7.063253632280827],
  [627.5092933868319, 6.548596726181507],
  [628.372116755113, 6.050614700744152],
  [629.2486609026193, 5.5760861990547195],
  [630.1496137109548, 5.128857836554051],
  [631.065992863059, 4.718384900951385],
  [631.9860639478322, 4.34861260527581],
  [632.92475428617, 4.009406651744842],
  [633.8886069332957, 3.711445266029239],
  [634.8592015357018, 3.4665974675893785],
  [635.8310406122431, 3.262277947105989],
  [636.8162881562887, 3.0877924496896103],
  [637.8028780400083, 2.940103402913734],
  [638.7954558215738, 2.8148393018156295],
  [639.7815353244023, 2.711023407579735],
  [640.7853282486797, 2.625185081264377],
  [641.7873714556695, 2.5605691818952563],
]

const line2 = [
  [0.7549184266204834, 56.99964151763916],
  [1.7487597637739478, 56.91497522883117],
  [2.7461628991292417, 56.83667871610224],
  [3.7440641866607667, 56.76428841400146],
  [4.743335726367235, 56.69804152228832],
  [5.733680593277663, 56.63924588249027],
  [6.72828442857042, 56.588184919723865],
  [7.725737337845177, 56.54671860763133],
  [8.736289336367753, 56.517381065133584],
  [9.740221767194987, 56.5054225820303],
  [10.734662536974252, 56.51340511240513],
  [11.737457076183262, 56.53959834076017],
  [12.733926545815766, 56.582783433456726],
  [13.725666402759552, 56.63870989122391],
  [14.727634762462676, 56.70063202709109],
  [15.730389275247752, 56.75845505167693],
  [16.73535570531845, 56.80400740118027],
  [17.72726198025465, 56.832235410773755],
  [18.72995638277054, 56.84263856019974],
  [19.727458206105233, 56.80554882251173],
  [20.715574852180485, 56.67941878916025],
  [21.698943283724784, 56.46899136826844],
  [22.661364723777773, 56.19890652655363],
  [23.61607456073761, 55.9015966209054],
  [24.56488890297413, 55.61868431745916],
  [25.539633875632287, 55.38017222278565],
  [26.531212388420105, 55.21739509004355],
  [27.533038029313087, 55.14409919112474],
  [28.522987326738242, 55.144960861767835],
  [29.530653157162668, 55.17232282670737],
  [30.528979829815032, 55.22220605052561],
  [31.516366295543314, 55.2899231026575],
  [32.52058668010235, 55.36882031008005],
  [33.514916087341305, 55.44501605300904],
  [34.51933134288788, 55.50865362482071],
  [35.50660684820712, 55.55104233972281],
  [36.503123811745645, 55.570933201014995],
  [37.51474860818386, 55.56941616452634],
  [38.50753507697581, 55.5519530634135],
  [39.50982136688232, 55.51890601634979],
  [40.51579289705753, 55.47275859121383],
  [41.50459106862545, 55.42040911065042],
  [42.51400104732513, 55.36796963479519],
  [43.5164646074295, 55.32467889072895],
  [44.500936911416055, 55.29559853343069],
  [45.51438306725025, 55.281477305963634],
  [46.506447584843635, 55.28317892857864],
  [47.50259335234165, 55.30141684943213],
  [48.50755852146148, 55.33732118707299],
  [49.49909144039154, 55.38842513036132],
  [50.5045332474947, 55.451286147577314],
  [51.4969362049818, 55.5167089919202],
  [52.49653628594876, 55.57805321829542],
  [53.49541699979306, 55.62900986984595],
  [54.5053358919859, 55.66716827023551],
  [55.497985704770684, 55.69278563216403],
  [56.4975051004678, 55.714823218593736],
  [57.50517459564209, 55.73359480705261],
  [58.49842787914277, 55.74806457152366],
  [59.48855216844082, 55.758223826617005],
  [60.49612986490429, 55.76453286986574],
  [61.493864408883454, 55.767694212471696],
  [62.48994763763845, 55.76891742508933],
  [63.496352833220364, 55.76919185045585],
  [64.48930430719852, 55.75826752001494],
  [65.49226945106983, 55.671379359172285],
  [66.4685074356079, 55.500032731914516],
  [67.44911931664944, 55.24675306651146],
  [68.39318832645417, 54.94457104338407],
  [69.33388414814472, 54.61702531221955],
  [70.28749654786586, 54.29312265810519],
  [71.24699052791595, 54.00464507695436],
  [72.20858792078495, 53.77032407204359],
  [73.1931535279721, 53.59266409671456],
  [74.18676139875949, 53.45033892823904],
  [75.18864742810726, 53.332683303081986],
  [76.17056565036773, 53.2432226893425],
  [77.17054193027019, 53.17526073309184],
  [78.17287001175583, 53.122623342920846],
  [79.16801146805287, 53.074869385373596],
  [80.17049794921874, 53.02118110351563],
  [81.16177367037237, 52.95602780690939],
  [82.16217058467866, 52.875091870617865],
  [83.16121502685546, 52.77690291900635],
  [84.15008663311005, 52.66291112692356],
  [85.15281481249333, 52.53478174349964],
  [86.13850150814056, 52.405234401059154],
  [87.12454101715088, 52.28408543529511],
  [88.13293019886018, 52.18120349423885],
  [89.13059221038819, 52.10831800403595],
  [90.11556742765903, 52.066940979477764],
  [91.1309191654317, 52.05443921161592],
  [92.11623029637336, 52.06542631778717],
  [93.12888517711683, 52.097500252088906],
  [94.11844187670425, 52.147896638312936],
  [95.12236404479668, 52.21316683050095],
  [96.1242366532147, 52.28334685804843],
  [97.11464947176202, 52.346991080924866],
  [98.10872843504325, 52.39642028146088],
  [99.10830623198972, 52.4267682269305],
  [100.12014926932753, 52.43658920332417],
  [101.10916320884229, 52.41069067675471],
  [102.10729122161865, 52.32966648521423],
  [103.10029356753826, 52.19058514272571],
  [104.0741584817171, 52.00073237245679],
  [105.06011843204497, 51.767462603235245],
  [106.03043552398681, 51.51759669532777],
  [106.9815424194336, 51.273199349975584],
  [107.96745661795138, 51.03622124271989],
  [108.9421261111647, 50.82716350624934],
  [109.92677072037758, 50.63290940743611],
  [110.90650712032615, 50.43332659794912],
  [111.88164808805287, 50.228193809273094],
  [112.84983537209034, 50.01969954347014],
  [113.83755824756622, 49.805089304494864],
  [114.81642111574114, 49.59436606154516],
  [115.78728592331707, 49.390682997050135],
  [116.77319913613796, 49.1913271874249],
  [117.74765794086456, 49.0023058866024],
  [118.73998942089081, 48.81220581951142],
  [119.71930425696075, 48.604318402692684],
  [120.69279072940351, 48.379979742884636],
  [121.65538671875, 48.15116171875],
  [122.63087134410442, 47.9284089455992],
  [123.62070661316812, 47.73098318727911],
  [124.59605720221997, 47.579814369082456],
  [125.60094484238326, 47.476438661119346],
  [126.59695839498937, 47.425725893744826],
  [127.58894791372121, 47.419876658002295],
  [128.60245647944512, 47.4440269026585],
  [129.58696574985981, 47.495269663625955],
  [130.58944588601588, 47.57481801831127],
  [131.58297840118408, 47.67540660438538],
  [132.57154267275334, 47.78672475450635],
  [133.57668899066746, 47.89890765067712],
  [134.57768154621124, 47.99930599579811],
  [135.56885595703125, 48.08174338378906],
  [136.55953509521487, 48.146511001586916],
  [137.5596530717164, 48.20977805346772],
  [138.56714707632364, 48.274853255128114],
  [139.5562665115595, 48.33738203334212],
  [140.55144175243376, 48.3951745914936],
  [141.5552466183454, 48.44423086882159],
  [142.5579390258789, 48.481123495483395],
  [143.5550086206049, 48.50502371505276],
  [144.5610698128939, 48.51714686428904],
  [145.5503219884634, 48.511962007331846],
  [146.53494140625003, 48.374268750000006],
  [147.49450524868072, 48.0708807980299],
  [148.4075241201967, 47.64079255173206],
  [149.26458740234375, 47.14560078125],
  [150.1230578793287, 46.61343904056549],
  [150.97554524360595, 46.10968459961414],
  [151.87923960323633, 45.662147892737394],
  [152.80654160560667, 45.33799326293469],
  [153.7925888748169, 45.16336868896485],
  [154.80574410067496, 45.14327736165673],
  [155.7902894639969, 45.15451602201462],
  [156.80214843682944, 45.17753509979993],
  [157.79808061145246, 45.20993973728567],
  [158.80499999999998, 45.24855],
  [159.79572786152363, 45.28657205723525],
  [160.790763387084, 45.31908263527155],
  [161.80123182685674, 45.34226600680799],
  [162.80425589932503, 45.353822638343274],
  [163.79729637493193, 45.352904916884015],
  [164.7852446426153, 45.330656440484525],
  [165.7999123080522, 45.27859389013798],
  [166.78144431507587, 45.195079882729054],
  [167.77349437046053, 45.07556085443496],
  [168.76306030404567, 44.9242485039115],
  [169.75460616838933, 44.74826623522043],
  [170.74126883423327, 44.55841021596193],
  [171.7158032319397, 44.36448276206106],
  [172.70695145221055, 44.16208735851497],
  [173.66758070147037, 43.91619869893789],
  [174.6243305682391, 43.61218465315849],
  [175.55183999955653, 43.27233379684687],
  [176.48710474395753, 42.90741661911011],
  [177.42531237123904, 42.55154388705641],
  [178.3831816791296, 42.23406199079752],
  [179.34067249572277, 41.9891994856],
  [180.33554228115082, 41.823954134464266],
  [181.32069299556312, 41.749239663253725],
  [182.3166795474291, 41.72465345748067],
  [183.32112384700775, 41.708187912511825],
  [184.3188113862276, 41.69958288667799],
  [185.32720856571197, 41.696882641839984],
  [186.32918135948478, 41.69667380794957],
  [187.31543552398682, 41.694666385269166],
  [188.31930087812245, 41.68713032454327],
  [189.32888562619684, 41.671843923610446],
  [190.33164946079253, 41.6484350256443],
  [191.32922421781717, 41.61441579104811],
  [192.31738958728312, 41.56333494974374],
  [193.31747594547275, 41.48865816831589],
  [194.31123966704308, 41.38681331097633],
  [195.31339566802978, 41.25411829147339],
  [196.29799418215453, 41.096606489993626],
  [197.28100301076472, 40.918205500380694],
  [198.26542974118888, 40.72522720878273],
  [199.2383418022394, 40.52629667376279],
  [200.22388213527202, 40.31215467527508],
  [201.18694201357664, 40.07315830255672],
  [202.14760284423826, 39.80458427429198],
  [203.10196202003954, 39.511712454766034],
  [204.05876166903974, 39.201201801568274],
  [205.0028249542266, 38.89131425868794],
  [205.9662961050123, 38.58510634899512],
  [206.92269574303924, 38.301048715930435],
  [207.88195839498937, 38.04090281668678],
  [208.85351547896863, 37.800082550626996],
  [209.81265307171643, 37.5509221643351],
  [210.78549412690106, 37.28272551826313],
  [211.74389585196974, 37.01380554018616],
  [212.70907538712027, 36.75465455798506],
  [213.69583666706086, 36.52188312125206],
  [214.67639009857177, 36.339601915359495],
  [215.66340365289153, 36.21374163163378],
  [216.65634695017337, 36.14483375498652],
  [217.65818539465963, 36.12786209493279],
  [218.65548451672493, 36.162121561759704],
  [219.6604575805664, 36.25134201660156],
  [220.64453931844236, 36.38781213622093],
  [221.62967986096442, 36.55924373460412],
  [222.61577503566446, 36.741992315310235],
  [223.6081886137724, 36.90935230174065],
  [224.58804690857232, 37.03633539426923],
  [225.59089905022086, 37.11489178265929],
  [226.58524571382998, 37.138899984925985],
  [227.5914577188492, 37.13863505911827],
  [228.58632750427722, 37.13665582591891],
  [229.59351763810218, 37.13043570523485],
  [230.58149449503424, 37.1171497494638],
  [231.5833738095313, 37.0934187413685],
  [232.58977824239435, 37.05741825976446],
  [233.58385664351283, 37.009983391874286],
  [234.58339304213226, 36.951882641113556],
  [235.58457979443483, 36.88525838405612],
  [236.58416320884228, 36.804758525401354],
  [237.58229122161868, 36.700154693984985],
  [238.55869620583948, 36.569342848811296],
  [239.54915848171711, 36.4044466190517],
  [240.53511843204498, 36.208576830053325],
  [241.50543552398682, 35.990050045394895],
  [242.47410509251057, 35.75441194281355],
  [243.4424566179514, 35.50943857383132],
  [244.4171261111647, 35.25968336872682],
  [245.38207337629794, 35.00016070092917],
  [246.34530321036277, 34.70304571088702],
  [247.28916262914242, 34.3762542723164],
  [248.22821660614014, 34.02397702102661],
  [249.1537521685362, 33.66469994069338],
  [250.0803746957779, 33.31172984304428],
  [251.02261492733658, 32.97794045033008],
  [251.98968154621127, 32.67458701200485],
  [252.94106981289386, 32.41998173750639],
  [253.9303219884634, 32.19969512064457],
  [254.91494140625, 32.01029765625],
  [255.89192904365063, 31.85083642437458],
  [256.8860367807895, 31.72109060594738],
  [257.8834762554616, 31.62387088325322],
  [258.88641292893885, 31.5540904869318],
  [259.8683877917528, 31.503452562975887],
  [260.8757995347977, 31.458861999320984],
  [261.87342475016413, 31.413489077040552],
  [262.87865819776056, 31.361718957829474],
  [263.87056530857086, 31.318589019584657],
  [264.87100665958224, 31.290882808956503],
  [265.86832356750966, 31.277682183623316],
  [266.86726505756377, 31.274496896934508],
  [267.86980782933534, 31.273983920678496],
  [268.8663900985718, 31.26785591583252],
  [269.87138653934005, 31.249527046227456],
  [270.8659396044165, 31.216190084812048],
  [271.8690496749878, 31.16637961082459],
  [272.8645433820487, 31.084201468008757],
  [273.8504575805664, 30.958571731567382],
  [274.83453931844235, 30.788700917309527],
  [275.8196798609644, 30.58021847034767],
  [276.789791434288, 30.35098671412468],
  [277.76491650390625, 30.114964965820313],
  [278.74246338282524, 29.88976419576332],
  [279.72283679115776, 29.686781474024055],
  [280.7118507306725, 29.51044752989486],
  [281.6848730249852, 29.347183179899304],
  [282.6697369890213, 29.173037222146988],
  [283.667339435935, 28.991892474526168],
  [284.64373702061175, 28.81731539207101],
  [285.62989525604246, 28.653357137680054],
  [286.6144758798033, 28.511120180691034],
  [287.6149534301758, 28.394148818969725],
  [288.6007252806425, 28.3069894833982],
  [289.61685450966655, 28.24390806052461],
  [290.59906039558354, 28.199453353615105],
  [291.6115963471085, 28.15928688542098],
  [292.59860990142823, 28.12680776901245],
  [293.6112140613794, 28.101442714488506],
  [294.5977349424362, 28.08471139230728],
  [295.5966764324903, 28.07481752258539],
  [296.61148670494555, 28.07006106799841],
  [297.5944346914291, 28.068471332263947],
  [298.6071261111647, 28.06820091574639],
  [299.61143300628663, 28.068242915344243],
  [300.6076044017524, 28.06873936339915],
  [301.5970767132044, 28.070463113856317],
  [302.61116333293916, 28.074589107704163],
  [303.59792461287975, 28.081993812394145],
  [304.5958644409179, 28.093636096191403],
  [305.60852275562286, 28.109840715599063],
  [306.60747130711377, 28.12971234053075],
  [307.597597236678, 28.152522952404617],
  [308.603882135272, 28.20757683914304],
  [309.58505489933486, 28.3582726134479],
  [310.561343208909, 28.602770285695794],
  [311.51416734218594, 28.914532036447522],
  [312.4546410614699, 29.258673348260672],
  [313.39904711687564, 29.595058185428382],
  [314.34629610501224, 29.879629925695806],
  [315.3211139589548, 30.086487787240742],
  [316.3222254923582, 30.194828129082918],
  [317.3150161281824, 30.207020232236385],
  [318.3232446426153, 30.183399445688725],
  [319.3207640322446, 30.135448441326613],
  [320.3194443150759, 30.066194829165937],
  [321.3114943704605, 29.984450732326508],
  [322.3010603040457, 29.90207302633524],
  [323.3096164733321, 29.829938716374336],
  [324.29760456420485, 29.779556624244155],
  [325.3138638067246, 29.752584507083892],
  [326.30634205913543, 29.749251376247408],
  [327.2994564794451, 29.75624926671535],
  [328.30119642044605, 29.771107942660155],
  [329.30271205301585, 29.79283129696995],
  [330.311738324523, 29.819082306182384],
  [331.3007833938599, 29.845237204742432],
  [332.3076149273366, 29.86824239218086],
  [333.31126883423326, 29.884657955324652],
  [334.3057868574858, 29.89325399698019],
  [335.297417252183, 29.891423671603203],
  [336.2938100818544, 29.84860767442882],
  [337.29828107501567, 29.750823654171825],
  [338.28539058685305, 29.599827864074705],
  [339.2638284584433, 29.402842477199435],
  [340.2356609026194, 29.17568557384014],
  [341.2038133170754, 28.938099720713495],
  [342.1788590039164, 28.705910148927572],
  [343.15842475016416, 28.492400498136877],
  [344.1428738888353, 28.302955345525593],
  [345.1175433820486, 28.104677076858284],
  [346.10345758056644, 27.876479092407227],
  [347.0710589250177, 27.63262926341519],
  [348.04081864051517, 27.382185669779034],
  [349.01084151828286, 27.144570978134873],
  [349.98470643246173, 26.938019371062516],
  [350.9777087783814, 26.773700192642213],
  [351.95654948227104, 26.66192569928691],
  [352.9648507306725, 26.596665089861304],
  [353.95748858642577, 26.56193233642578],
  [354.9587572942972, 26.53865239317417],
  [355.95408349609374, 26.52660224609375],
  [356.96115848171706, 26.522921134305],
  [357.9630600282401, 26.52264380738437],
  [358.96700482006366, 26.519468861964345],
  [359.95659166006743, 26.508346795412898],
  [360.9692952928543, 26.48578499011993],
  [361.9546756226271, 26.452238185951114],
  [362.9504335066676, 26.394326391375067],
  [363.9546448998451, 26.292194342327118],
  [364.9354849935845, 26.147675994049017],
  [365.9284247208237, 25.959712064063545],
  [366.8985693193301, 25.746420486803352],
  [367.88257932710644, 25.517109974575042],
  [368.8470046718195, 25.296587696562703],
  [369.828472998254, 25.08915020276457],
  [370.8185972465947, 24.904083365319668],
  [371.80534081248936, 24.730511778692154],
  [372.7701608657837, 24.538079056930542],
  [373.7490609741211, 24.32382597961426],
  [374.735663151741, 24.100045562887193],
  [375.7079034184069, 23.889011191583425],
  [376.6855541139841, 23.705318881720306],
  [377.6880342501402, 23.561779075294734],
  [378.67254352055494, 23.471377738703044],
  [379.66565794086455, 23.429854371404648],
  [380.6781930047721, 23.440412963254747],
  [381.6743954357952, 23.529904432131353],
  [382.64535399627687, 23.695305554962157],
  [383.62245364888014, 23.9278795605585],
  [384.597, 24.19885],
  [385.5553746957779, 24.46554549703598],
  [386.53161647333206, 24.698853230296073],
  [387.5196045642048, 24.867795567868647],
  [388.51580699522793, 24.95728703674525],
  [389.5078406658098, 24.967252590230107],
  [390.5024442281127, 24.9166088173151],
  [391.5053942510486, 24.80772312424183],
  [392.4914289253131, 24.65078581031263],
  [393.46935353606943, 24.463695249772073],
  [394.4574001011849, 24.269981838798522],
  [395.4439612731934, 24.100642053222657],
  [396.4228442115784, 23.977267320251467],
  [397.4272309399918, 23.907037155053022],
  [398.4157733105868, 23.893647053433952],
  [399.42123046875, 23.916262890625003],
  [400.433135017395, 23.969172475433353],
  [401.4252570153028, 24.047466165731848],
  [402.4217694749683, 24.14377701189071],
  [403.4089664778858, 24.242926829843224],
  [404.4085249757767, 24.3318576376915],
  [405.40049287967383, 24.39729482971579],
  [406.3999266237021, 24.434649818050865],
  [407.40634104000026, 24.44091587794572],
  [408.3962496733815, 24.37350283329934],
  [409.3950590219498, 24.204897349834443],
  [410.3584148214012, 23.9453134597823],
  [411.2941803266257, 23.613977065540848],
  [412.2163738095313, 23.23687629326433],
  [413.13994550907614, 22.84314182895422],
  [414.05795343017576, 22.468137493896485],
  [415.0057102937699, 22.12218582830429],
  [415.955959321022, 21.830034187602998],
  [416.9237824401334, 21.57313333915025],
  [417.8918650131822, 21.30965067440271],
  [418.85647587244955, 21.04261859010905],
  [419.82949555587766, 20.776413457489014],
  [420.7868863959312, 20.527751048183443],
  [421.76277303954214, 20.298343223355708],
  [422.74338565301895, 20.09947710428238],
  [423.73803345823285, 19.932173797130584],
  [424.7223884278312, 19.79839399045855],
  [425.71529637493194, 19.689450448028744],
  [426.7032446426153, 19.594384106743334],
  [427.7007640322446, 19.51266011711359],
  [428.6994443150759, 19.44806406005621],
  [429.70737134329977, 19.401799248315395],
  [430.6972127339393, 19.373690044985715],
  [431.7066460037231, 19.358867995452883],
  [432.6959685668945, 19.35307933959961],
  [433.69386380672455, 19.351663426685334],
  [434.7066980077699, 19.365449323914948],
  [435.6980794258118, 19.50328534164429],
  [436.6466995247677, 19.773977116046847],
  [437.56856228685376, 20.152524756526947],
  [438.4691671252251, 20.59727577829361],
  [439.35960593652726, 21.05784753599167],
  [440.26824310827254, 21.489941969013216],
  [441.20581323146075, 21.843739440335334],
  [442.17690004348754, 22.079030300140378],
  [443.1548550627604, 22.17133234634846],
  [444.1566530498266, 22.16651224836707],
  [445.1675532226563, 22.14036486816406],
  [446.17007588136204, 22.0914908280313],
  [447.16522916664184, 22.018970727352052],
  [448.15164487318697, 21.925399166505784],
  [449.1511022191793, 21.814197334779053],
  [450.14948670494556, 21.694203789454697],
  [451.1324346914292, 21.574062692403793],
  [452.12434180223937, 21.455456011921168],
  [453.12977072037756, 21.333733904556187],
  [454.10950712032616, 21.20001023879722],
  [455.1014750242233, 21.049293947935105],
  [456.08497528076174, 20.887475387573243],
  [457.07233755791185, 20.71856131270528],
  [458.0520578164607, 20.55242189726308],
  [459.0421685739756, 20.393408210664987],
  [460.0323469282836, 20.248050570022315],
  [461.0324649047851, 20.116872744750978],
  [462.02297900390624, 19.999900488281252],
  [463.0139701013565, 19.888622863197327],
  [464.0148563349396, 19.783101779571176],
  [465.00384636791057, 19.687173465183378],
  [466.0085215835571, 19.599115187072755],
  [467.0020438917876, 19.520956660723687],
  [467.98723960323633, 19.450594820550087],
  [468.9888548234254, 19.3841202408582],
  [469.9817662115097, 19.321210996055605],
  [470.99419300477206, 19.258449694041165],
  [471.99039543579516, 19.197517281735692],
  [472.9783835266679, 19.13956185826734],
  [473.9869396959543, 19.085505590218308],
  [474.9765056295395, 19.040292161607745],
  [475.9847888940126, 19.004432171865552],
  [476.9844045999199, 18.979762287973614],
  [477.98332179273666, 18.96511229692027],
  [478.9729838718176, 18.95882840793729],
  [479.98604254646597, 18.96835569102243],
  [480.98563899613913, 19.039422186896953],
  [481.959236612916, 19.174474731069804],
  [482.9542721384764, 19.37170584098697],
  [483.91324783146376, 19.59879531238675],
  [484.88721273393924, 19.83672716149762],
  [485.86260616838933, 20.0511615432322],
  [486.84926883423327, 20.22043373606801],
  [487.8437868574857, 20.33120615388751],
  [488.85584464381634, 20.385080232949555],
  [489.8504926375151, 20.422937306749823],
  [490.85353122556205, 20.45555351954699],
  [491.8559561082125, 20.48141546577215],
  [492.8494784164429, 20.499789923858646],
  [493.8380379799604, 20.511277851927282],
  [494.8431436650604, 20.517429562278096],
  [495.8440298986435, 20.519929652881622],
  [496.8549637648612, 20.520566306434574],
  [497.84604459331933, 20.52059958636686],
  [498.84439932155607, 20.520573527479172],
  [499.85044123935694, 20.520439478349687],
  [500.8548794631958, 20.52007552833557],
  [501.84921330797675, 20.519346645623447],
  [502.83733684825893, 20.518140081548694],
  [503.8408373708576, 20.516369928979127],
  [504.8392054103613, 20.514091253489255],
  [505.84694487120214, 20.511347158969194],
  [506.8369868230969, 20.50670792249516],
  [507.8388762873411, 20.474526726907488],
  [508.8478314260244, 20.399839419525865],
  [509.83794218353927, 20.280055306734887],
  [510.81766244208814, 20.115836000007388],
  [511.80502471923825, 19.911129751586916],
  [512.7717154879124, 19.68494257012382],
  [513.7443955982476, 19.445448423651605],
  [514.7209396044165, 19.204311006183175],
  [515.6822465475799, 18.97316365898251],
  [516.6620121869296, 18.730061452747137],
  [517.6170069244952, 18.47436550838128],
  [518.5899660997391, 18.193322076368332],
  [519.5466261904686, 17.89751394937709],
  [520.4847481251954, 17.59241052362323],
  [521.4374758798033, 17.273655340226743],
  [522.3854097979217, 16.9545183612369],
  [523.3289246379882, 16.640394190638514],
  [524.2946780115366, 16.325974985593557],
  [525.2441440429687, 16.021089086914063],
  [526.1987311657667, 15.705883329445127],
  [527.1343050788789, 15.38839721665904],
  [528.07987890625, 15.0612818359375],
  [529.0236167513877, 14.732832097949833],
  [529.9645426727534, 14.408906507223843],
  [530.9189390258789, 14.08920104675293],
  [531.8796967896373, 13.779785061388463],
  [532.8232292796224, 13.48915106350854],
  [533.7870496749879, 13.204742304992676],
  [534.7443935012818, 12.910804606628417],
  [535.6980656862258, 12.598067075920104],
  [536.6368757427483, 12.27981344437301],
  [537.594158481717, 11.960174210047724],
  [538.5482486490012, 11.66649940035343],
  [539.5174606815576, 11.41306248948574],
  [540.4840030107647, 11.217437331160902],
  [541.4874566179514, 11.077434480643273],
  [542.4829503250122, 10.9973845993042],
  [543.4858565822392, 10.95587842488885],
  [544.480240166068, 10.9282529815197],
  [545.4851434249879, 10.91306434020996],
  [546.481694540158, 10.90798622739911],
  [547.4842347040177, 10.907561137771609],
  [548.4831600004434, 10.904921576356887],
  [549.4804097979218, 10.893982573157547],
  [550.4807102937699, 10.871023181533815],
  [551.4723981369734, 10.835282561635971],
  [552.4777707203776, 10.762543230417519],
  [553.4575071203261, 10.602525401591212],
  [554.4326480880528, 10.347561116063744],
  [555.368663151741, 10.016261296548844],
  [556.2932616754771, 9.622226862450837],
  [557.1887278615236, 9.203964000374079],
  [558.0986149273365, 8.77275798104629],
  [559.0110086206049, 8.360314569162577],
  [559.9379266237021, 7.979220452746153],
  [560.8820496749878, 7.6376344499969475],
  [561.8202701741606, 7.299180156374127],
  [562.7577269214393, 6.939147141469717],
  [563.6820834960938, 6.568716928710938],
  [564.6092396670432, 6.1928105621360245],
  [565.5318282428533, 5.829610826978236],
  [566.4811041480302, 5.4837256782352926],
  [567.4221685739756, 5.180763313194513],
  [568.3936006784438, 4.9149989382267],
  [569.3714845210313, 4.694506889039278],
  [570.3430416050106, 4.515903849000036],
  [571.3390551576167, 4.3671095531395085],
  [572.3269337483198, 4.256027420659363],
  [573.335445510745, 4.179460676810741],
  [574.3250056276322, 4.13375229845047],
  [575.3170471168756, 4.104410172331333],
  [576.3328435533493, 4.0758905555745955],
  [577.3316391495913, 4.0368131207796925],
  [578.3211020175219, 3.9801656841397284],
  [579.3139119027852, 3.9035608916682007],
  [580.3152179633379, 3.8106630441576246],
  [581.3072366129161, 3.7058774681741],
  [582.3022721384765, 3.593399050783515],
  [583.293, 3.48298875],
  [584.2999193885476, 3.3833937716466935],
  [585.2958515631706, 3.3064742802051454],
  [586.2892318268567, 3.255687015554681],
  [587.292255899325, 3.230921351565197],
  [588.2852963749319, 3.2497217424222824],
  [589.2732446426153, 3.4388450583577157],
  [590.2019657498598, 3.793748314816952],
  [591.0738958519698, 4.2657554410004614],
  [591.9276798609644, 4.818832755791843],
  [592.7382486490011, 5.373715110981465],
  [593.5924779129177, 5.925874303717316],
  [594.4652859233171, 6.396071170376241],
  [595.3953217927367, 6.748263368313015],
  [596.3849838718176, 6.933852780487538],
  [597.3777745076418, 6.939946125359535],
  [598.378886041045, 6.836965400881767],
  [599.3537038949877, 6.640518395565152],
  [600.3171750457733, 6.365449649934173],
  [601.2612383309603, 6.046579992318153],
  [602.2019310423284, 5.721821173513533],
  [603.1723971557617, 5.424866569824219],
  [604.1330579864234, 5.202743665508628],
  [605.1356097974776, 5.064770719261169],
  [606.1231077470779, 5.022004345340729],
  [607.1303611114174, 5.0061221025684475],
  [608.1263507210016, 4.992600009396076],
  [609.1368958519697, 4.981588843801021],
  [610.1338740844727, 4.973694317626953],
  [611.1209158297777, 4.968689236147403],
  [612.1365249757768, 4.965915176486968],
  [613.1284928796739, 4.9647679263481495],
  [614.127926623702, 4.964445430839062],
  [615.134402763322, 4.964420961092711],
  [616.1245286928863, 4.964581963549851],
  [617.1234772443771, 4.965532397537231],
  [618.136135559082, 4.968303461914062],
  [619.1340753871202, 4.974020826749801],
  [620.1208366670609, 4.983645645065307],
  [621.1349232867956, 4.998195595712661],
  [622.1243955982477, 5.01679127432704],
  [623.1205669937134, 5.039281471557617],
  [624.1248229866773, 5.068620642611757],
  [625.118298108682, 5.240800121797249],
  [626.03229378964, 5.600048881800846],
  [626.8869824765176, 6.11429692289792],
  [627.6864785766602, 6.737733420715331],
  [628.4197478317022, 7.403532516381144],
  [629.1363054598421, 8.105807775731012],
  [629.8504214500635, 8.818594352936968],
  [630.5572396032363, 9.502838637860194],
  [631.3007806471437, 10.171540588755533],
  [632.0930008717924, 10.805692188506201],
  [632.9142185271512, 11.366686497526011],
  [633.7758549686298, 11.883928448722513],
  [634.6366133662416, 12.378526926161069],
  [635.5097768618101, 12.863391964095738],
  [636.3902282179138, 13.33866881842846],
  [637.2732454664111, 13.803982713919877],
  [638.163389019303, 14.263233552075178],
  [639.05780220222, 14.715938110483904],
  [639.9490222206116, 15.159065926742553],
  [640.844604554236, 15.596694034618139],
  [641.759095703125, 16.0351060546875],
]

const lines = [line1, line2]

const padding = 10

const Graph = ({ animFunc, clearAnimFunc }) => {
  const canvasRef = useRef()
  const percentRef = useRef()
  const companyLabelsRef = useRef()
  const [canvasSize, setCanvasSize] = useState()
  const [lineLengths, setLineLengths] = useState()
  const [percentVal, setPercentVal] = useState(0)
  const lengthsAnims = useRef()
  const percent = useRef({ value: percentVal })

  useEffect(() => {
    const [x1, y1] = line1[0]
    const [x2, y2] = line1[line1.length - 1]
    const [x3, y3] = line2[0]
    const [x4, y4] = line2[line2.length - 1]

    const left = Math.min(Math.min(x1, x2), Math.min(x3, x4))
    const right = Math.max(Math.max(x1, x2), Math.max(x3, x4))

    const top = Math.min(Math.min(y1, y2), Math.min(y3, y4))
    const bottom = Math.max(Math.max(y1, y2), Math.max(y3, y4))

    const canvasSizeObj = {
      width: Math.abs(Math.ceil(right - left)) + padding * 2,
      height: Math.abs(Math.ceil(bottom - top)) + padding * 2,
    }
    setCanvasSize(canvasSizeObj)

    let distance1 = 0
    let distance2 = 0
    for (let i = 0; i < line1.length - 1; i++) {
      distance1 += getDistance(...line1[i], ...line1[i + 1])
    }
    for (let i = 0; i < line2.length - 1; i++) {
      distance2 += getDistance(...line2[i], ...line2[i + 1])
    }

    lengthsAnims.current = {
      line1: distance1,
      line2: distance2,
      dots: canvasSizeObj.height - padding,
    }

    setLineLengths({
      ...lengthsAnims.current,
    })

    percent.current.value = 0
    setPercentVal(percent.current.value)
  }, [])

  const draw = () => {
    if (canvasSize) {
      const canvas = canvasRef.current
      const ctx = canvas.getContext("2d")
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.lineWidth = 5
      ctx.lineCap = "round"

      // and that's the bottom line
      ctx.lineWidth = 0.5
      ctx.setLineDash([0, 0])
      ctx.lineDashOffset = 0
      ctx.strokeStyle = "#0063AD"
      ctx.beginPath()
      ctx.moveTo(padding, canvas.height - padding + 3)
      ctx.lineTo(canvas.width - padding, canvas.height - padding + 3)
      ctx.stroke()
      ctx.closePath()

      // dotted line
      ctx.lineWidth = 2
      ctx.setLineDash([2, 10])
      ctx.lineDashOffset = 0
      ctx.strokeStyle = "#000000"
      ctx.beginPath()
      ctx.moveTo(padding, lengthsAnims.current.dots)
      ctx.lineTo(canvas.width - lengthsAnims.current.line1 - padding - 30, lengthsAnims.current.dots)
      ctx.stroke()
      ctx.closePath()

      let lineYOffset
      ctx.lineWidth = 5

      // green line
      ctx.setLineDash([lineLengths.line2, lineLengths.line2])
      ctx.lineDashOffset = lengthsAnims.current.line2
      ctx.strokeStyle = "#32A39D"
      ctx.beginPath()
      lineYOffset = canvas.height - line2[0][1] - padding
      line2.forEach(([x, y], i) => ctx[i ? "lineTo" : "moveTo"](x + padding, y + lineYOffset))
      ctx.stroke()
      ctx.closePath()

      // blue line
      ctx.setLineDash([lineLengths.line1, lineLengths.line1])
      ctx.lineDashOffset = lengthsAnims.current.line1
      ctx.strokeStyle = "#0063AD"
      ctx.beginPath()
      lineYOffset = canvas.height - line1[0][1] - padding
      line1.forEach(([x, y], i) => ctx[i ? "lineTo" : "moveTo"](x + padding, y + lineYOffset))
      ctx.stroke()
      ctx.closePath()
    }
  }

  const clearAnim = () => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext("2d")
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    gsap.to(percentRef.current, { y: canvasRef.current.height })

    gsap.set(lengthsAnims.current, { dots: lineLengths.dots })

    const { current: companyLabels } = companyLabelsRef
    for (let i = 0; i < companyLabels.childNodes.length; i++) {
      const lineTarget = `line${i + 1}`
      const label = companyLabels.childNodes[i]

      gsap.set(lengthsAnims.current, {
        [lineTarget]: lineLengths[lineTarget],
        onUpdate: () => gsap.set(label, { x: `0%` }),
      })

      gsap.to(label, { alpha: 0 })
    }
  }

  const anim = () => {
    draw()

    const ease = CustomEase.create("custom", "M0,0,C0.104,0.204,0.734,0.928,1,1")
    const duration = 2

    gsap.to(percent.current, {
      duration,
      value: 400,
      ease,
      onUpdate: () => setPercentVal(Math.round(percent.current.value)),
    })

    gsap.to(lengthsAnims.current, { duration, dots: padding, ease })

    const { current: companyLabels } = companyLabelsRef
    const companyLabelsY = companyLabels.getBoundingClientRect().height
    for (let i = 0; i < 2; i++) {
      const lineTarget = `line${i + 1}`

      const start = lengthsAnims.current[lineTarget]
      const label = companyLabels.childNodes[i]
      const labelHeight = label.getBoundingClientRect().height

      gsap.to(lengthsAnims.current, {
        duration,
        [lineTarget]: 0,
        delay: 0.2 * i,
        onUpdate: () => gsap.set(label, { x: `${(1 - lengthsAnims.current[lineTarget] / start) * 100}%` }),
      })
      const line = lines[i]
      let targetY = 0
      line.forEach(([x, y]) => {
        targetY = Math.max(y, targetY)
      })
      gsap.set(label, { y: -targetY - companyLabelsY - labelHeight * (0.8 + i * 0.7) })
      gsap.fromTo(
        label,
        { alpha: 0 },
        { duration: duration * 0.75, ease: Power4.easeIn, delay: duration * 0.25 + 0.2 * i, alpha: 1 }
      )
    }

    gsap.fromTo(
      percentRef.current,
      { y: canvasRef.current.height },
      { y: padding + percentRef.current.getBoundingClientRect().height / 2, duration, ease }
    )

    gsap.to({}, { duration: duration + 1, onUpdate: draw })
  }

  useEffect(() => {
    if (canvasSize) {
      if (!animFunc.current) {
        animFunc.current = anim
        clearAnimFunc.current = clearAnim
      }
      clearAnim()
    }
  }, [canvasSize])

  if (!canvasSize) return null

  return (
    <div className="graph">
      <p className="percentage" style={{ right: canvasSize.width }} ref={percentRef}>{`${percentVal}%`}</p>
      <canvas width={canvasSize.width} height={canvasSize.height} ref={canvasRef} />
      <div className="years">
        <p>2006</p>
        <p>2023</p>
      </div>
      <div className="company-labels" ref={companyLabelsRef}>
        <p className="blue">CDLI</p>
        <p className="green">Investment Grade Bonds</p>
      </div>
    </div>
  )
}

export default Graph
