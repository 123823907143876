import Button from "../button/Button"
import logo from "../../assets/images/logo.svg"

import "./site-nav.scss"

const SiteNav = () => {
  return (
    <div className="site-nav">
      <img className="logo" src={logo} alt={logo} />
      <div className="nav">
        <p>CCLFX</p>
        <p>CELFX</p>
        <p>ABOUT CLIFFWATER</p>
        <p>RESEARCH</p>
        <Button label="Contact Us" />
      </div>
    </div>
  )
}

export default SiteNav
