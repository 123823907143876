import React, { useLayoutEffect, useRef, useState } from "react"

import gsap, { Power2 } from "gsap/dist/gsap"

import "./line-banner.scss"
const fps = 30
const delay = 1000 / fps
const step = (2 * Math.PI) / fps

const colors = [
  "#2B3F78",
  "#536491",
  "#3B4E82",
  "#61719B",
  "#6B7AA1",
  "#7D8AAC",
  "#8D98B6",
  "#909AB8",
  "#9EA7C1",
  "#C3C9D9",
  "#F1F2F6",
  "#D1D6E2",
]

const compWidth = 1512
const compHeight = 508
const spacing = 35
const paths = [
  [
    [1704.2377839322387, 161.04228434160052],
    [1675.7571649169922, 133.00062938232423],
    [1644.334934314443, 108.28242806075447],
    [1610.884920710574, 86.35846987224453],
    [1575.838116336721, 67.11884352536359],
    [1539.4629362082308, 50.48806953039802],
    [1502.0257515034057, 36.42544393097399],
    [1463.7381560498684, 24.86950443336021],
    [1424.7995831788608, 15.748986082282023],
    [1385.3919775756808, 8.977498986035576],
    [1345.6443597696139, 4.451537428521343],
    [1305.7195240286428, 2.0652823215851175],
    [1265.726394335055, 1.7013653674961233],
    [1225.764314211656, 3.240418658654029],
    [1185.9023012061825, 6.564236370497111],
    [1146.224276860081, 11.554603894381197],
    [1106.7631726040697, 18.10150432034418],
    [1067.5609440624573, 26.098786532127797],
    [1028.5730137985847, 35.03128964934422],
    [989.5584340939488, 43.812585646011385],
    [950.4836778824913, 52.381335221263264],
    [911.3525723074108, 60.72028425196282],
    [872.1904225564778, 68.80682937477616],
    [832.9626568252428, 76.62959969712635],
    [793.677276861569, 84.16682979279992],
    [754.3439160961149, 91.39512279552468],
    [714.9344286298235, 98.29607087120567],
    [675.4828349732836, 104.83815804348338],
    [635.947015755174, 111.00030805347042],
    [596.3665496685002, 116.74552903191662],
    [556.7260174764141, 122.04246297275442],
    [517.0148842837355, 126.85480826198868],
    [477.2465724115004, 131.13896839383642],
    [437.4212617546662, 134.84815969595448],
    [397.52783776390953, 137.93040062035664],
    [357.59902201793597, 140.32351539928354],
    [317.63989909660296, 141.9600112155924],
    [277.6490767848329, 142.76258780424857],
    [237.65484522039068, 142.64161429805011],
    [197.66636305286718, 141.49387482178935],
    [157.72653196759558, 139.20019561484057],
    [117.89665619522125, 135.6243225314662],
    [78.21464476317743, 130.6046518188898],
    [38.77619473656727, 123.95740996880171],
    [-0.30777370577397534, 115.46933012945325],
    [-38.8787319597443, 104.89937556664657],
    [-76.72967437402383, 91.9783922608046],
    [-114.09170978040406, 77.71993737131527],
    [-152.16547771308464, 65.44544163046812],
    [-190.81107442706818, 55.106148542017166],
    [-229.85899755731305, 46.47520301792606],
    [-269.22605088349565, 39.35294218647418],
    [-308.79528553101426, 33.58383865571559],
    [-348.53412188544706, 29.02839322896443],
    [-388.38438923179694, 25.57602906487274],
    [-428.3088289794128, 23.133402816702493],
    [-468.27596736405854, 21.623468610482053],
    [-508.27972615712656, 20.98331897144634],
    [-548.278132244774, 21.164395568211326],
    [-588.2582082700428, 22.131022087113834],
    [-628.215094032014, 23.864404970771577],
    [-668.1474165271504, 26.3705293212537],
    [-708.0027584973122, 29.701631566489613],
  ],
  [
    [1703.2840579213948, 150.01951043097898],
    [1672.8685676292228, 124.11289568012987],
    [1639.6157007269758, 101.91765468107263],
    [1604.5080119497236, 82.76664737526208],
    [1568.0183691758764, 66.41239315606968],
    [1530.4590894319135, 52.67982403166273],
    [1492.0732097699595, 41.42239231433766],
    [1453.086115069259, 32.50930806329805],
    [1413.6563529008054, 25.803389872209138],
    [1373.9202832156768, 21.172895770259885],
    [1334.0143388455235, 18.490979501111855],
    [1294.0251314771203, 17.631368639929242],
    [1254.049478485212, 18.473709330681615],
    [1214.1185822553311, 20.9050337176398],
    [1174.3143069176367, 24.81666388667253],
    [1134.6796291479773, 30.106726688190918],
    [1095.2208410633957, 36.68529724576879],
    [1055.9599054858131, 44.37553273271531],
    [1016.7306140063295, 52.15704020322241],
    [977.4543527688421, 59.75373703997325],
    [938.1497027349374, 67.14466049113533],
    [898.7961338477811, 74.31513052863872],
    [859.3949385101847, 81.24491331156162],
    [819.9489249323498, 87.91210509118753],
    [780.4625074498115, 94.29301429305654],
    [740.9227422050228, 100.36488369555613],
    [701.3382090423313, 106.09945773906144],
    [661.7008373309684, 111.46866426169726],
    [622.0239526470567, 116.438931541276],
    [582.2868878986677, 120.9779948501021],
    [542.4912840875525, 125.04707917304663],
    [502.6424981033043, 128.60322530991175],
    [462.7498654561639, 131.59895093770382],
    [422.82698123199987, 133.9818876973445],
    [382.85857868118245, 135.69552777220744],
    [342.8696082369571, 136.67458150931435],
    [302.8743548762911, 136.8473092959071],
    [262.8942715986281, 136.1336771286438],
    [222.92785809519452, 134.44292752411513],
    [183.01494212919175, 131.67375682727274],
    [143.21913760870243, 127.71559907915042],
    [103.56992003185572, 122.44139069557622],
    [64.13832170938765, 115.71269062407768],
    [25.02034344011777, 107.37997330277902],
    [-13.677295863884034, 97.28043117285885],
    [-51.87510767566164, 85.41170689260532],
    [-90.38948284015056, 74.62963619797924],
    [-129.3913329447719, 65.78635337420616],
    [-168.7461759423854, 58.62130693315278],
    [-208.33457532150024, 52.92150437202286],
    [-248.09201131505253, 48.504327203004195],
    [-287.9551732702675, 45.21800223650702],
    [-327.8765704162836, 42.932181531498365],
    [-367.8518311605784, 41.53258319639144],
    [-407.8603219339519, 40.92015197366193],
    [-447.8571489368681, 41.00431420786416],
    [-487.8448178385959, 41.698118366122166],
    [-527.836143128633, 42.914261236781385],
    [-567.8001969134689, 44.5531560393609],
    [-607.7529472751111, 46.4863945734948],
    [-647.7039545604862, 48.50603471591318],
    [-687.6618238938231, 50.14597769335637],
    [-727.6105834606553, 49.162511640551855],
  ],
  [
    [1702.3356787244884, 139.00032146610238],
    [1669.7638982627336, 115.84904203379122],
    [1634.6399920486865, 96.77023353366137],
    [1597.943846988222, 80.84353424460981],
    [1560.1798765046583, 67.67446064943813],
    [1521.6616674980344, 56.980498817953354],
    [1482.558649962798, 48.52429347017775],
    [1443.0824312086902, 42.12628063682548],
    [1403.3465961037386, 37.615356312065614],
    [1363.443414584159, 34.8418698712683],
    [1323.462488515852, 33.67282614501799],
    [1283.4622916969686, 33.987117264953135],
    [1243.5059490701756, 35.67478416675334],
    [1203.6026261095717, 38.638390705097656],
    [1163.82965653069, 42.78428405748618],
    [1124.166822574231, 48.03507278640198],
    [1084.6711011213217, 54.311454883986784],
    [1045.2314971694068, 61.04184575124193],
    [1005.7763019090795, 67.60611966515003],
    [966.3030673663413, 73.98689992306129],
    [926.7703224035331, 80.17218122986098],
    [887.2163969827552, 86.13582214322962],
    [847.640664542254, 91.85656670374914],
    [808.0062143701535, 97.31638466670496],
    [768.3527249006477, 102.48464875890986],
    [728.6463342055341, 107.33865916840367],
    [688.8919701737553, 111.84814338699773],
    [649.1130738104374, 115.9787262212551],
    [609.2811782961182, 119.69892633321406],
    [569.4219329558861, 122.96897680880198],
    [529.5107391452883, 125.7503253183805],
    [489.57542525792513, 127.99701745017528],
    [449.61136454119014, 129.66191023393063],
    [409.63181535196173, 130.69305292033872],
    [369.63537424825404, 131.03476010490425],
    [329.62265525620546, 130.6262091881399],
    [289.6411654522546, 129.40292627242997],
    [249.70752532128134, 127.29656306439364],
    [209.8247220999326, 124.23280265302441],
    [170.03986820773812, 120.13588313916051],
    [130.37078882066433, 114.92366647691063],
    [90.90334645090655, 108.52063050532801],
    [51.65203723643622, 100.83959126919528],
    [12.683416015111554, 91.8008102935048],
    [-26.215655762872647, 82.48030125320798],
    [-65.5125144394287, 75.03957883871013],
    [-105.0882998153475, 69.2659807546145],
    [-144.86289326926772, 64.92096946882991],
    [-184.7347462609992, 61.810157695489266],
    [-224.6812472262926, 59.76374621528256],
    [-264.6630826228228, 58.6386713569405],
    [-304.65579492425263, 58.309065596844405],
    [-344.6523427253047, 58.6617634631882],
    [-384.64862163721324, 59.591799834528885],
    [-424.6253778079339, 60.99631393125387],
    [-464.58984765769253, 62.76951498215644],
    [-504.5319876266431, 64.79258158063917],
    [-544.4774906253225, 66.92154064741548],
    [-584.4278087812854, 68.9559338887073],
    [-624.3967452725362, 70.57555347389814],
    [-664.3786756962703, 71.15099137300024],
    [-704.2886262969155, 68.91453267785548],
  ],
  [
    [1701.3937804160453, 127.97782485045222],
    [1666.551797767816, 108.43408983515147],
    [1629.6083402827362, 93.10860163348742],
    [1591.563379674483, 80.8334733728361],
    [1552.7774498057024, 71.04533030367182],
    [1513.5271395919601, 63.39832940815361],
    [1473.9338241167266, 57.62305795525648],
    [1434.1470923946852, 53.51915157484828],
    [1394.2437502156326, 50.91613707893346],
    [1354.2602177642389, 49.671774395393804],
    [1314.2595426633, 49.66728565969579],
    [1274.2840934052301, 50.798775884933136],
    [1234.3437031194119, 52.976666098198336],
    [1194.4761371196016, 56.121009106907024],
    [1154.6809115492795, 60.16323780295841],
    [1114.9663727797358, 65.04230361854239],
    [1075.365199839838, 70.57301781092328],
    [1035.72879234966, 76.05684946136081],
    [996.0849712044129, 81.37097929900602],
    [956.4152580290787, 86.50047826188548],
    [916.7211728243196, 91.42617873054111],
    [877.0039185765186, 96.12742707978923],
    [837.2461805127713, 100.58396360813833],
    [797.4667214479532, 104.76962529747033],
    [757.6647133129937, 108.65877729845866],
    [717.8212179161652, 112.22539752600403],
    [677.9507000618333, 115.43825887705249],
    [638.0483787609171, 118.26588909441523],
    [598.1237566367041, 120.67378281477397],
    [558.167257651253, 122.62666195470685],
    [518.1980089301618, 124.08597590450097],
    [478.2150535661789, 125.01271049143465],
    [438.2135447356779, 125.36626304171973],
    [398.2140180059963, 125.10482459782568],
    [358.21631921852975, 124.18638670111183],
    [318.25794353842684, 122.5704342803635],
    [278.3264278977056, 120.21606160447953],
    [238.44604097131017, 117.08565142637518],
    [198.6340874078062, 113.14450886248908],
    [158.92869940679503, 108.3647102888358],
    [119.33435280066297, 102.71995653452157],
    [79.87750694835901, 96.19250082134752],
    [40.54460843110898, 88.87575861330714],
    [1.0168848694781403, 82.72813996552699],
    [-38.72072759961995, 78.2530141547132],
    [-78.59508402558878, 75.19036532746657],
    [-118.56070894532706, 73.32620707532737],
    [-158.54212406091062, 72.48217728150814],
    [-198.5552666646887, 72.50330509569872],
    [-238.53662300349706, 73.25458822786447],
    [-278.52608296736616, 74.61550161110688],
    [-318.4659041941552, 76.47131624292767],
    [-358.4096000144541, 78.7151410519517],
    [-398.3320887018792, 81.23451429146071],
    [-438.2403086761927, 83.90951989320317],
    [-478.15372434531173, 86.60241548524957],
    [-518.0799189819128, 89.13998188449604],
    [-558.0059609049181, 91.28464023952104],
    [-597.9960850025486, 92.68021692030293],
    [-637.9879190074442, 92.70036439181848],
    [-677.8874161415093, 90.02265281424752],
    [-716.6445073396295, 80.70355338947584],
  ],
  [
    [1700.4518821076024, 116.95632823211336],
    [1663.376356276574, 102.06486532211994],
    [1624.9042048182707, 91.15434308183426],
    [1585.786959555723, 82.82732471962137],
    [1546.2879600149038, 76.49265814380271],
    [1506.5757793209136, 71.79864436184727],
    [1466.7184296851176, 68.49786258881417],
    [1426.7580818910567, 66.40925937144198],
    [1386.7804704874536, 65.39467194779594],
    [1346.7735228405218, 65.34019723072105],
    [1306.7855991615018, 66.15370854469231],
    [1266.8250106037758, 67.75763145159091],
    [1226.896453304569, 70.08663346818618],
    [1186.9967047264204, 73.08547579652631],
    [1147.1749839474733, 76.70165402318203],
    [1107.3811033058512, 80.8964924535251],
    [1067.630729512232, 85.2561231804841],
    [1027.855207060274, 89.45214851315758],
    [988.048211593349, 93.4694500100636],
    [948.2398890837223, 97.28802474288177],
    [908.4023280347766, 100.89221434462249],
    [868.5430894064904, 104.26140639948844],
    [828.66614645876, 107.37399397705947],
    [788.7546411864965, 110.20847852501487],
    [748.8373688095814, 112.73880556363012],
    [708.9028043999825, 114.94073494769887],
    [668.9489773766971, 116.78832798757989],
    [628.9658488240212, 118.25523539290488],
    [588.9944855323569, 119.31344797480251],
    [548.988998464638, 119.93802200196663],
    [508.98177224338394, 120.10317774118332],
    [468.99087996805724, 119.78632010622736],
    [428.9931440085388, 118.96740483176629],
    [389.02367652239496, 117.6311375577286],
    [349.07755439032337, 115.76636777672402],
    [309.1404813408387, 113.36620718537807],
    [269.24502373857683, 110.4324217232587],
    [229.39120001821615, 106.97141150137834],
    [189.60422052873702, 102.9981657348707],
    [149.856329273707, 98.52823985099654],
    [110.15090515733924, 93.58488279797702],
    [70.4473136342298, 88.72112580904628],
    [30.594451130122007, 85.4312493004894],
    [-9.367558027529915, 83.55729106002286],
    [-49.35764191394755, 82.87087203836559],
    [-89.357035394581, 83.17918238810637],
    [-129.34066268079468, 84.31864225960452],
    [-169.29819763596967, 86.14680281205399],
    [-209.22665533657278, 88.53652326507185],
    [-249.12320833013263, 91.37042078799158],
    [-288.992971706436, 94.53666860565481],
    [-328.85515576097487, 97.92499299364209],
    [-368.70261415701515, 101.41780220315951],
    [-408.5470257259397, 104.88719701016929],
    [-448.41059172390237, 108.18485105253008],
    [-488.3064775839583, 111.12548437769543],
    [-528.2396905917049, 113.46243293653725],
    [-568.2142472756166, 114.8427215677262],
    [-608.2041481471981, 114.71208983074419],
    [-648.1152069761938, 112.09227290845249],
    [-687.4327352047363, 104.97428218912647],
  ],
  [
    [1699.5050863089762, 105.93544690526699],
    [1660.5708676537517, 96.89687592775755],
    [1621.020964141283, 90.95553169891055],
    [1581.2338458218305, 86.75744538862924],
    [1541.3480561176573, 83.83201453237369],
    [1501.4038538792856, 81.91416659190614],
    [1461.40933694491, 80.83220450207756],
    [1421.4189350081876, 80.4658868777942],
    [1381.4160626440882, 80.72396279206085],
    [1341.4297821509545, 81.53563438631082],
    [1301.4513843680875, 82.84439399270643],
    [1261.4850395099752, 84.60388935921415],
    [1221.5500623841078, 86.77442125681762],
    [1181.6242679997424, 89.32435184287434],
    [1141.735317403734, 92.22368881504771],
    [1101.8659928848542, 95.38661101938774],
    [1061.9709156888887, 98.4347015032235],
    [1022.0852234050996, 101.29253551842478],
    [982.1634234845226, 103.94856358498039],
    [942.2335728399642, 106.38503872100037],
    [902.3000225319512, 108.58523685879679],
    [862.3448374351053, 110.53289784171072],
    [822.3769742936478, 112.20983236686203],
    [782.4102978048534, 113.59789663724995],
    [742.4296277080508, 114.68051409833453],
    [702.4210091365337, 115.44173627910479],
    [662.4402620232772, 115.86661127895836],
    [622.4329466011455, 115.94471217781168],
    [582.437158206038, 115.66863862676375],
    [542.437330823835, 115.03614955156488],
    [502.4491847496361, 114.05115060190235],
    [462.4663715097989, 112.72368518944123],
    [422.50484013371215, 111.07125386004702],
    [382.55515223709443, 109.11691525160492],
    [342.6159515999713, 106.88957298617643],
    [302.6879714680152, 104.42268892544345],
    [262.78136253437583, 101.75334074307099],
    [222.88171179496936, 98.91827583047188],
    [182.9945509471528, 95.955264826708],
    [143.1071225747087, 92.89927737601496],
    [103.17588356790498, 90.65298396700459],
    [63.182182177890866, 89.85342658649927],
    [23.189060669227782, 90.25273376469757],
    [-16.793108022664455, 91.64549196568571],
    [-56.7201635960775, 93.85684715120902],
    [-96.62931218164906, 96.73959889792049],
    [-136.47652278216583, 100.15835496656845],
    [-176.28670783496838, 103.99417816023431],
    [-216.06976752535095, 108.13437655090813],
    [-255.8303903802241, 112.46841841903739],
    [-295.59260591404234, 116.88618158605378],
    [-335.36082104677183, 121.2686813603033],
    [-375.12634788735204, 125.48274301481602],
    [-414.94158312097625, 129.38044908200862],
    [-454.7953914399932, 132.77366682156398],
    [-494.6985992090184, 135.4240271012992],
    [-534.6691375997282, 137.00783256804777],
    [-574.6688412532778, 137.05454260344962],
    [-614.6040441209452, 134.83614356977807],
    [-654.1693742396301, 129.13490137043007],
    [-692.4636428169187, 117.7546913353291],
  ],
  [
    [1698.5541429771783, 94.91357085751133],
    [1658.6236898983766, 92.89039228514812],
    [1618.6426574550433, 92.32321842420612],
    [1578.6322367026996, 92.32113559388178],
    [1538.6436897147917, 92.69743037338188],
    [1498.6392366159578, 93.36010599493608],
    [1458.6543307470006, 94.25271732923613],
    [1418.6686989946702, 95.33791639988581],
    [1378.6762740994754, 96.58874474040215],
    [1338.717015459138, 97.98324515277142],
    [1298.7441813114365, 99.50684001342422],
    [1258.7804564792743, 101.1457263409516],
    [1218.8134288879278, 102.88972663679024],
    [1178.8573480669424, 104.72926979900589],
    [1138.904446456994, 106.65709057649961],
    [1098.945000769273, 108.55108063039467],
    [1058.9744710640007, 110.234185605462],
    [1019.0087259601895, 111.69096568418801],
    [979.0306355776527, 112.90832073137784],
    [939.0391848333281, 113.87249125917978],
    [899.0435923404815, 114.57014297801028],
    [859.041819085768, 114.98958119184626],
    [819.0473218346992, 115.12130713722017],
    [779.0514233317252, 114.95947699692957],
    [739.0481791006692, 114.5028723417265],
    [699.0541099027322, 113.7569968878727],
    [659.0590121674716, 112.73497746938195],
    [619.0896100120846, 111.46015789020001],
    [579.1154094382387, 109.96402046658527],
    [539.1445211874694, 108.28869235306233],
    [499.19145280879616, 106.48488427864638],
    [459.2274533899124, 104.6069015375212],
    [419.28447894657944, 102.71401257768446],
    [379.33261863806837, 100.86111116728037],
    [339.35588301705764, 99.0991223606991],
    [299.40315692524473, 97.47457818693474],
    [259.42166414015276, 96.02117572938768],
    [219.44230968941915, 94.76711399796633],
    [179.4624572418654, 93.77606258339073],
    [139.45482400147773, 93.90158048717831],
    [99.48002074820637, 95.26711978995947],
    [59.54527804089363, 97.65105623117189],
    [19.688686760313903, 100.86583885225171],
    [-20.12207966303985, 104.75581849958851],
    [-59.888412835448975, 109.18315853289366],
    [-99.58973621584849, 114.0207267143525],
    [-139.25976289289667, 119.15688842807585],
    [-178.90119446180503, 124.48154831297664],
    [-218.52973500269854, 129.8875586007336],
    [-258.16899349481935, 135.26623814613455],
    [-297.82879960688456, 140.4999243070736],
    [-337.51463589118373, 145.45808189890695],
    [-377.2493024456652, 149.99311793822963],
    [-417.05865167993306, 153.92828221278205],
    [-456.9387561507845, 157.04029433052872],
    [-496.88768533808144, 159.0414543628545],
    [-536.8887249520566, 159.5425385344057],
    [-576.8442046864423, 157.9942601676653],
    [-616.5906772888819, 153.58699330199903],
    [-655.650070915812, 145.0957105879811],
    [-692.9090665869336, 130.69960751258355],
  ],
  [
    [1697.6131850040947, 83.89169320028654],
    [1658.0689824563547, 89.67972488402822],
    [1618.3939844305405, 94.74385177408615],
    [1578.6094579834698, 98.9686582651901],
    [1538.7786744174948, 102.56430053193546],
    [1498.8955513996523, 105.66247988657133],
    [1458.9800539940784, 108.34841192197294],
    [1419.0532026877136, 110.68269844785317],
    [1379.1030012722404, 112.71236740662158],
    [1339.1470053061737, 114.47241293194577],
    [1299.1663824980158, 115.9922372870346],
    [1259.1974188720578, 117.29387360079215],
    [1219.203743141126, 118.39795198729567],
    [1179.2246940397017, 119.31979007715115],
    [1139.2234333572144, 120.07211477439685],
    [1099.233342979076, 120.59906264484134],
    [1059.2275001246092, 120.86128568457465],
    [1019.2297766306583, 120.84439244168301],
    [979.2280413510442, 120.53531214526318],
    [939.240576045392, 119.92331193717862],
    [899.2582066315266, 119.00109016751993],
    [859.2706227222401, 117.76662378748864],
    [819.2975966167802, 116.22698538028214],
    [779.343309485255, 114.40018895856608],
    [739.3850163571817, 112.31710311076287],
    [699.4633570449653, 110.02882326070953],
    [659.5299129997821, 107.59973361491643],
    [619.6107441430671, 105.11556221294921],
    [579.6905476672999, 102.6724170573012],
    [539.7605294724062, 100.37235725747983],
    [499.8013177134998, 98.31348566950791],
    [459.84095896039094, 96.58394355573172],
    [419.8641894992284, 95.2506580193523],
    [379.8778068514232, 94.35969401774946],
    [339.87849397219435, 93.93553796022603],
    [299.88638720950587, 93.98450639251774],
    [259.8767624637476, 94.49885695638483],
    [219.90373640199235, 95.53894404176707],
    [179.95757138404423, 97.71271707142313],
    [140.0841325502158, 100.96123136341079],
    [100.30427318943606, 105.08185138640513],
    [60.596157716535004, 109.90706112317753],
    [20.950192676599947, 115.29157952231319],
    [-18.62412109525348, 121.10377346425359],
    [-58.143183732902585, 127.22710620071797],
    [-97.64587419477135, 133.55653659338313],
    [-137.1230788719541, 139.98344435060181],
    [-176.59759054710852, 146.40485658341953],
    [-216.10351900931278, 152.7167647280255],
    [-255.63291750944512, 158.8017518406819],
    [-295.21341723994374, 164.5370437103931],
    [-334.8842833192211, 169.7842272471354],
    [-374.610453500504, 174.3711929713908],
    [-414.4445373599408, 178.1026888591696],
    [-454.35634963887355, 180.72589912750684],
    [-494.33470742712257, 181.92139381667752],
    [-534.3126670425966, 181.26733443659003],
    [-574.1926803938146, 178.1931307344036],
    [-613.6847699722487, 171.9222735106454],
    [-652.2525253877493, 161.41241878173102],
    [-688.8483436033019, 145.38790980831865],
  ],
  [
    [1696.6622416722973, 72.8698172998732],
    [1659.1436485255363, 86.57310913778437],
    [1620.6601277218251, 97.4210175749196],
    [1581.5834793564818, 105.95789544213609],
    [1542.18280112071, 112.79379611168105],
    [1502.5519484984738, 118.2979778592771],
    [1462.7913109906392, 122.70687622779023],
    [1422.9489025993369, 126.19199465458762],
    [1383.0525431167066, 128.88168329053678],
    [1343.0875950763143, 130.87648132481718],
    [1303.123075711039, 132.25214302726087],
    [1263.1245904943228, 133.07427230747402],
    [1223.1225521757503, 133.3949823220288],
    [1183.1375761989989, 133.2589940126468],
    [1143.1376805917514, 132.7122304711518],
    [1103.148231278663, 131.8328601847237],
    [1063.1580250311285, 130.61793200849127],
    [1023.190005201157, 129.0513263195431],
    [983.2364277648749, 127.11911584780403],
    [943.3042668057942, 124.81349569780227],
    [903.3869008484627, 122.13501473970426],
    [863.5189397853524, 119.10125064746518],
    [823.6554074320517, 115.74397292918309],
    [783.821996591953, 112.12720511458637],
    [743.9893691430542, 108.34314168830686],
    [704.1727722928683, 104.52349725446014],
    [664.3499623941772, 100.8275902171603],
    [624.5043103406765, 97.4332718838078],
    [584.6034883221231, 94.51466604637288],
    [544.6652088316524, 92.22409986335148],
    [504.70123972086844, 90.66909560961477],
    [464.7035936374939, 89.90621493419603],
    [424.7056265398865, 89.94680982465147],
    [384.72216729894126, 90.76568038519056],
    [344.74005505971456, 92.31586695537814],
    [304.8085635978584, 94.5360036480313],
    [264.9261131626326, 97.42739763616508],
    [225.11335627160534, 101.37242474085788],
    [185.4126354402893, 106.26422122498934],
    [145.81797001471483, 111.91767494114478],
    [106.29993162416346, 118.17873757009102],
    [66.87886819002964, 124.90557770795438],
    [27.512257664697497, 131.98124205356035],
    [-11.817689447252393, 139.29528216272038],
    [-51.11711271593539, 146.74157960588872],
    [-90.41194409844388, 154.22112153413806],
    [-129.72805941269837, 161.63530153672016],
    [-169.0544805025756, 168.87606692586598],
    [-208.4427964500151, 175.84124117575763],
    [-247.90141842859344, 182.41111341802937],
    [-287.44297439021335, 188.4531362455176],
    [-327.07935944820576, 193.81563780565287],
    [-366.83139870958587, 198.32166789429817],
    [-406.67336547939703, 201.75374572077325],
    [-446.61713351249267, 203.8502406987231],
    [-486.6191901712631, 204.27927206719323],
    [-526.5813156559194, 202.62101013190664],
    [-566.3445637964062, 198.34147269420842],
    [-605.5888680190237, 190.77496326174662],
    [-643.8380191579371, 179.11159358837295],
    [-680.1994447039843, 162.5253236064804],
  ],
  [
    [1695.7212690650776, 61.84814125226047],
    [1661.7387228842992, 82.73251002578476],
    [1625.3960849691296, 99.4064397263457],
    [1587.6051022390318, 112.4305544513941],
    [1548.9382906192561, 122.68413218575922],
    [1509.7564956781612, 130.7176519739996],
    [1470.2421794945574, 136.92523318784902],
    [1430.5200414436968, 141.59330543473175],
    [1390.6695125303281, 144.94043002065567],
    [1350.7234355833502, 147.13851680269693],
    [1310.7376420960134, 148.32261454784654],
    [1270.7464164098435, 148.60434676743188],
    [1230.7442153330114, 148.0760134576018],
    [1190.7737003996065, 146.81539702717703],
    [1150.8174553433264, 144.92731283557814],
    [1110.88368841327, 142.61460508092125],
    [1070.97144296337, 139.87574963697105],
    [1031.1032569463941, 136.68920117882828],
    [991.2807179814978, 133.0377005928839],
    [951.4947592473153, 128.9132259646557],
    [911.7516369654634, 124.3271741926377],
    [872.0656604955628, 119.32123443828331],
    [832.4160237556043, 113.97572060583066],
    [792.8144744603486, 108.43291985297974],
    [753.1901168258083, 102.89130433003722],
    [713.5437741251413, 97.6230134429596],
    [673.827554325512, 92.92988313557464],
    [634.008324144632, 89.10333398678519],
    [594.0937290039062, 86.37168510742188],
    [554.1368697513485, 84.86196621521344],
    [514.1313905295518, 84.59414105749785],
    [474.1393752600536, 85.51069892077712],
    [434.19674665855086, 87.50561677353235],
    [394.3113307703947, 90.4556136402774],
    [354.49111774080257, 94.23788152104807],
    [314.73842110794726, 98.76895059466273],
    [275.13207624586295, 104.2476947292891],
    [235.62019477041605, 110.58589731059595],
    [196.24070525834568, 117.60301915333365],
    [156.9622366579376, 125.15353132029587],
    [117.77185812210331, 133.10727372276796],
    [78.62767011582994, 141.35358378640208],
    [39.53166604544234, 149.78218615092118],
    [0.4453272299172646, 158.29750647174768],
    [-38.63801667820318, 166.80037808075363],
    [-77.74373568245274, 175.19631827293117],
    [-116.91374354512098, 183.39221120623836],
    [-156.11666145808914, 191.27507063540605],
    [-195.420836452175, 198.74476893444267],
    [-234.81670153948144, 205.67452771711945],
    [-274.3187714095603, 211.9277505060445],
    [-313.9440563342812, 217.34828375061488],
    [-353.70704620097285, 221.7528663738098],
    [-393.581751124266, 224.91992872046336],
    [-433.5448838146741, 226.58593139121456],
    [-473.53549573567415, 226.4305776035766],
    [-513.4617957293381, 224.0642929171371],
    [-553.1341186032137, 219.0195971841295],
    [-592.2518651482378, 210.7549791699619],
    [-630.3611933029354, 198.6838737260003],
    [-666.8166222641923, 182.25566606071476],
    [-700.748570024257, 161.13761885523374],
  ],
  [
    [1694.775175431502, 50.827605612799935],
    [1665.2267044702533, 77.55105899453429],
    [1632.044106371677, 99.82806413820339],
    [1596.216923089403, 117.55420095751485],
    [1558.7565188317403, 131.52661541875224],
    [1520.2703458272708, 142.3826099590088],
    [1481.133044077166, 150.62559448491436],
    [1441.5927171288688, 156.6513965136317],
    [1401.8068713153543, 160.77634284847008],
    [1361.8789833169371, 163.25423683031244],
    [1321.904976559165, 164.29042624001556],
    [1281.9098795231826, 164.05518949514766],
    [1241.9292543501647, 162.688121592443],
    [1202.0047808961308, 160.30745628191755],
    [1162.1389755338714, 157.08455520674926],
    [1122.3149164843912, 153.33050531652268],
    [1082.5311939919932, 149.0375024638025],
    [1042.8455003200384, 144.1765564227387],
    [1003.216378264995, 138.71475238403514],
    [963.6703593169882, 132.64356880472246],
    [924.2309511465695, 125.98649730405656],
    [884.8840887962059, 118.81502538271116],
    [845.6013737600675, 111.28169840830196],
    [806.324115722295, 103.64919794595632],
    [767.0120832382593, 96.3149714137266],
    [727.5512587899916, 89.76188200059775],
    [687.9095079611739, 84.48975783724318],
    [648.0629133424449, 80.87232897622988],
    [608.1229434707726, 79.08236838324585],
    [568.1279611205445, 79.08063537213765],
    [528.1635753554065, 80.69821172233502],
    [488.26661519821914, 83.71238403316539],
    [448.4925736500779, 87.89651969432167],
    [408.8261204412621, 93.05367100867123],
    [369.27347096811945, 99.01968443031802],
    [329.8693602274455, 105.83914575102406],
    [290.59049782086527, 113.4535639816847],
    [251.44102236100403, 121.69461230718191],
    [212.40805515744205, 130.4193337367668],
    [173.46483223050467, 139.50507665061772],
    [134.57355420053523, 148.84487682205747],
    [95.70602697912935, 158.33876810175667],
    [56.86451508027715, 167.88523097797332],
    [18.011637291309814, 177.39584956463258],
    [-20.873982464119468, 186.77932490664185],
    [-59.81402544194834, 195.94317297887187],
    [-98.81086131151542, 204.78707627310925],
    [-137.9180573245866, 213.2163915824263],
    [-177.13037304510226, 221.11471209538144],
    [-216.4718814377626, 228.36059988250827],
    [-255.94019026841306, 234.81284987998825],
    [-295.5567534517928, 240.31539221162194],
    [-335.32806716307493, 244.68479055434383],
    [-375.2093074004855, 247.7028280461492],
    [-415.18527341483707, 249.1186578697903],
    [-455.16650449294184, 248.63508124290848],
    [-495.06772313730283, 245.90606936492975],
    [-534.6956979960806, 240.53782353830553],
    [-573.7872259118758, 232.10021415713106],
    [-611.9499170327799, 220.16552042685788],
    [-648.6791852191053, 204.36806944081005],
    [-683.3648758528683, 184.50205333185693],
  ],
  [
    [1693.8346942393202, 39.805760796746796],
    [1668.909852351305, 70.82105243759531],
    [1639.7085236169087, 98.10300477030606],
    [1606.673031142829, 120.59496835163348],
    [1571.0142045660662, 138.64554208971128],
    [1533.594274751906, 152.75480961048052],
    [1495.0669868018801, 163.42112998835904],
    [1455.8141851340163, 171.12762358254952],
    [1416.1565718332872, 176.2770434620973],
    [1376.2666978587436, 179.21996146989324],
    [1336.2836489674714, 180.2453348414722],
    [1296.288072955393, 179.59455324393062],
    [1256.3597651155706, 177.46967662798278],
    [1216.4996935855124, 174.0366075345819],
    [1176.7558236315385, 169.5214361430117],
    [1137.0872252948532, 164.34222222825355],
    [1097.5243494683125, 158.49619795760077],
    [1058.0629422935538, 151.92615288616594],
    [1018.7429054342323, 144.58950099694323],
    [979.5852357891208, 136.4631166204858],
    [940.5899078030083, 127.56706231496933],
    [901.7474833615199, 118.0100219652919],
    [863.0112646734264, 108.0468826667617],
    [824.2573712002486, 98.1375692041561],
    [785.307799220364, 88.97452979983233],
    [746.0515845364739, 81.39362099877889],
    [706.4079637622555, 76.09076771827404],
    [666.4979670338926, 73.39743489999546],
    [626.5097546135198, 73.22547025223027],
    [586.5653935644477, 75.2358057392353],
    [546.7501565356881, 79.02591805677768],
    [507.08851382908244, 84.23189362362936],
    [467.5859507014886, 90.5578399913798],
    [428.2468478532071, 97.7716268276613],
    [389.06705325746975, 105.76581181115438],
    [350.03120855545234, 114.51315706069133],
    [311.1303608538346, 123.85620106475184],
    [272.35641242840603, 133.65450359889428],
    [233.65255167726758, 143.79667514077704],
    [195.0239022613011, 154.16787294365844],
    [156.4319546672443, 164.67362568988437],
    [117.85055918683102, 175.22247790419453],
    [79.24946725264479, 185.72782707898313],
    [40.614393669268054, 196.10027722998237],
    [1.9298532451812527, 206.25047488891028],
    [-36.8382759840351, 216.09162014816118],
    [-75.70431224404135, 225.52737763054614],
    [-114.69860721554826, 234.458803040828],
    [-153.8291526526512, 242.77286564177604],
    [-193.09892299308973, 250.34487361373226],
    [-232.54051675038937, 257.04159728354443],
    [-272.14152309765655, 262.7039958135999],
    [-311.87982104560274, 267.1523916744708],
    [-351.7735765965365, 270.1861468124205],
    [-391.73926772999687, 271.5669728255373],
    [-431.72990390263396, 271.0286681756942],
    [-471.6307472926509, 268.2699543550003],
    [-511.26344051345757, 262.964468990756],
    [-550.407302768598, 254.77096634314148],
    [-588.7323044036768, 243.37072072833453],
    [-625.8498158002391, 228.5036885204227],
    [-661.3077380971515, 210.02891146828196],
    [-694.6600723606825, 187.96246028637196],
  ],
].map((line, l) => {
  return {
    alpha: 0,
    color: colors[l],
    line: line.filter(([x, y]) => {
      return x >= -spacing && x <= compWidth + spacing
    }),
  }
})

const getDefaults = () => {
  return {
    amplitude: { amount: 68, sinAmount: -20, sinDuration: 48 },
    wavelength: { amount: 10.05, sinAmount: 0, sinDuration: 0 },
    echos: { amount: 1, sinAmount: 0, sinDuration: 0 },
    linewidth: { amount: 1, sinAmount: 0, sinDuration: 0 },
    offsetX: { amount: 0, sinAmount: 2, sinDuration: 39 },
    offsetY: { amount: 0, sinAmount: 9, sinDuration: 48 },
    lineSpacing: { amount: 0, sinAmount: 0, sinDuration: 0 },
    clampStart: { amount: 0, sinAmount: 0, sinDuration: 0 },
    clampEnd: { amount: 0, sinAmount: 0, sinDuration: 0 },
    speed: { amount: 4, sinAmount: 0, sinDuration: 0 },
  }
}

const scale = 5
const getSinValue = (it, speed, prop, round = false, abs = false) => {
  const { amount, sinDuration, sinAmount } = prop

  if (!sinDuration && !sinAmount) return amount

  let value = sinDuration === 0 ? 0 : Math.sin(it * (speed / (sinDuration * Math.PI * scale))) * sinAmount
  if (round) value = Math.round(value)

  return amount + (abs ? Math.abs(value) : value)
}

const LineBannerAltSlow = () => {
  const canvasRef = useRef()
  const contextRef = useRef()
  const canvasHalfHeight = useRef()
  const timeoutRef = useRef()

  const [defaultValues, setDefaultValues] = useState()
  const [values, setValues] = useState()

  const firstLoad = useRef(true)
  const firstDraw = useRef(true)

  const valuesObj = useRef()

  useLayoutEffect(() => {
    //

    if (values) {
      valuesObj.current = {
        wavelength: values.wavelength,
        amplitude: values.amplitude,
        echos: values.echos,
        linewidth: values.linewidth,
        offsetX: values.offsetX,
        offsetY: values.offsetY,
        lineSpacing: values.lineSpacing,
        clampStart: values.clampStart,
        clampEnd: values.clampEnd,
        speed: values.speed,
      }
    }

    if (!values) {
      const defaults = getDefaults()
      setDefaultValues(defaults)
      setValues(defaults)

      firstLoad.current = false
    }

    if (values && firstDraw.current) {
      const { current: canvas } = canvasRef

      contextRef.current = canvas.getContext("2d")
      contextRef.current.lineWidth = 0

      canvasHalfHeight.current = canvas.height / 4

      paths.forEach((lineObj, l) => {
        gsap.fromTo(
          lineObj,
          { alpha: 0 },
          { alpha: 1, ease: Power2.easeOut, duration: 1.75, delay: 0.075 * (paths.length - l) }
        )
      })

      draw(0)
      firstDraw.current = false
    }
  }, [values])

  const draw = it => {
    const { wavelength, amplitude, echos, linewidth, offsetX, offsetY, lineSpacing, clampStart, clampEnd, speed } =
      valuesObj.current

    const { current: canvas } = canvasRef
    if (!canvas) return

    const { current: ctx } = contextRef
    const { current: halfHeight } = canvasHalfHeight

    //
    //
    //

    ctx.clearRect(0, 0, canvas.width, canvas.height)

    const speedAmount = getSinValue(it, 1, speed, false, true)
    const waveAmount = getSinValue(it, speedAmount, wavelength, false, false)
    const ampAmount = getSinValue(it, speedAmount, amplitude)
    const offX = getSinValue(it, speedAmount, offsetX)
    const offY = getSinValue(it, speedAmount, offsetY)
    const clampBack = getSinValue(it, speedAmount, clampEnd, false, true)

    paths.forEach(({ alpha, color, line }, l) => {
      if (!alpha) return

      const lineYOffset = offY * l
      const lineXOffset = l * offX

      ctx.strokeStyle = color
      if (alpha !== 1) {
        ctx.lineWidth = alpha * (4 / 3)
        ctx.globalAlpha = alpha
      }

      ctx.beginPath()

      line.forEach(([x, y], p) => {
        const percent = p / line.length
        const backClamp = 1 - clampBack * percent

        ctx.lineTo(
          x,
          halfHeight + y + (lineYOffset + Math.sin((it + p + lineXOffset) / waveAmount) * ampAmount * backClamp) / scale
        )
      })

      ctx.stroke()
      ctx.closePath()
    })

    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => draw(it + step / speedAmount), delay)
  }

  if (!values || !defaultValues) return <div>Loading.</div>

  return (
    <div className="line-banner">
      <canvas width={compWidth} height={compHeight} ref={canvasRef}></canvas>
    </div>
  )
}

export default LineBannerAltSlow
